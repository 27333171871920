import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 35"}</title>
      <g transform="translate(-1069 -1548)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={1548} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1104 1570h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1v-12a1 1 0 00-1-1zm-1 12h-12v-9h12v9z" />
          <path d="M1093 1575h2v2h-2zM1096 1575h2v2h-2zM1093 1578h2v2h-2zM1096 1578h2v2h-2zM1099 1575h2v2h-2zM1099 1578h2v2h-2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
