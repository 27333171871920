import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 12"}</title>
      <g transform="translate(-1069 -378)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={378} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1095.3 409.7l-2-2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3 3.3-3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4 4c-.4.4-.9.5-1.4 0z" />
          <path d="M1104 399h-3v2h2v11h-12v-11h2v-2h-3c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-13c0-.6-.4-1-1-1z" />
          <path d="M1094 398h6v4h-6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
