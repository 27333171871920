import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 14"}</title>
      <g transform="translate(-427 -378)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={378} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M450 408h10v3h-10z" />
          <path d="M461 399h-12a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2v-12a2 2 0 00-2-2zm-12 13v-10h12v10h-12z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
