import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 34"}</title>
      <g transform="translate(-1069 -1314)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={1314} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1102 1349h-12a1 1 0 01-1-1v-10a1 1 0 011-1h4v2h-3v8h10v-3h2v4a1 1 0 01-1 1z" />
          <path d="M1105 1338l-4-4v3c-3.309 0-6 2.691-6 6h2c0-2.206 1.794-4 4-4v3l4-4z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
