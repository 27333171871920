import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M34 22H20a1 1 0 00-1 1v4h6v-2l4 3-4 3v-2h-6v4a1 1 0 001 1h3v4l6-4h5a1 1 0 001-1V23a1 1 0 00-1-1z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
