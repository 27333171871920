import * as React from "react";

function SvgApiDocumentation(props) {
  return (
    <svg width={16} height={14} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M5 6H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1zM8 0h8v2H8zM8 4h8v2H8zM0 8h16v2H0zM0 12h16v2H0z" />
      </g>
    </svg>
  );
}

export default SvgApiDocumentation;
