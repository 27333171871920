export const COMPETITORS = [
	{
		slug: 'maintainx',
		name: 'MaintainX',
		comparison: [false, true, false, false, true, false, false, true, false, false, 2],
	},
	{
		slug: 'maximo',
		name: 'Maximo',
		comparison: [false, true, false, false, true, false, false, true, false, true, 3],
	},
	{
		slug: 'fiix',
		name: 'FiiX',
		comparison: [false, true, false, true, true, false, false, true, false, true, 2],
	},
	{
		slug: 'emaint',
		name: 'eMaint',
		comparison: [false, true, false, false, true, false, false, true, false, true, 2],
	},
	{
		slug: 'limble',
		name: 'Limble',
		comparison: [false, true, false, false, true, false, false, true, false, true, 2],
	},
	{
		slug: 'efacility',
		name: 'eFacility',
		comparison: [false, true, false, false, true, false, false, false, true, false, 'unknown'],
	},
	{
		slug: 'planon',
		name: 'Planon',
		comparison: [false, false, false, false, true, true, false, true, true, false, 3],
	},
	{
		slug: 'spacewell',
		name: 'Spacewell',
		comparison: [false, true, false, true, true, true, false, true, false, true, 'unknown'],
	},
];
