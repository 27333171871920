import * as React from "react";

function SvgAssetManagement(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M15.207 8.793l-5.5-5.5a.999.999 0 00-1.414 0l-7 7a1.003 1.003 0 00-.263.464l-1 4a1.002 1.002 0 001.213 1.213l4-1a.998.998 0 00.464-.263L12 8.414 13.086 9.5l-3.293 3.293a.999.999 0 101.414 1.414l4-4a.999.999 0 000-1.414zM11.12 1.879l3 3 1.259-1.258a2.122 2.122 0 00-3-3L11.12 1.879z" />
      </g>
    </svg>
  );
}

export default SvgAssetManagement;
