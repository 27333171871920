import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6 14H2V2h10v3.5L14 7V1a1 1 0 00-1-1H1a1 1 0 00-1 1v14a1 1 0 001 1h5v-2z"
        fill="#F8644B"
      />
      <path
        d="M12 8l-4 3v5h3v-3h2.035v3H16v-5l-4-3zM10 4H4v1h6V4zM10 7H4v1h6V7zM7 10H4v1h3v-1z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
