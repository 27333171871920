import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 42"}</title>
      <g transform="translate(-741 -1782)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={1782} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M766 1803h11v2h-11zM766 1809h11v2h-11zM766 1815h11v2h-11zM761.368 1806v-.549l.598-.048c.097-.007.11-.035.11-.139v-2.424c0-.083-.02-.125-.09-.145l-.604-.126.077-.569h1.666v3.264c0 .111.007.132.111.139l.57.048v.549h-2.438zM761.047 1812v-.63l.907-.886c.684-.663.975-.934.975-1.32 0-.25-.122-.433-.48-.433-.373 0-.529.122-.529.603l-.92-.082c.007-1.076.826-1.252 1.536-1.252 1.05 0 1.422.447 1.422 1.083 0 .636-.44 1.056-.934 1.53l-.765.73h.995c.067 0 .095-.013.108-.08l.095-.535h.717V1812h-3.127zM763.344 1815.682c.567.087.88.427.88 1.026 0 .88-.66 1.292-1.612 1.292-.673 0-1.272-.24-1.612-.613l.533-.586c.253.247.533.44.999.44.373 0 .693-.133.693-.6 0-.406-.253-.573-.666-.573a2.73 2.73 0 00-.493.047v-.666l.326-.04c.48-.06.74-.293.74-.72 0-.252-.114-.459-.487-.459-.36 0-.532.12-.532.593l-.913-.08c0-1.052.793-1.232 1.492-1.232 1.026 0 1.419.393 1.419 1.106 0 .56-.347.912-.767 1.039v.026z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
