import * as React from "react";

function SvgPhotoAndVideoEvidence(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M15 2H6V0H2v2H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-1 12H2V4h12v10z" />
        <circle cx={9} cy={9} r={3} />
      </g>
    </svg>
  );
}

export default SvgPhotoAndVideoEvidence;
