import * as React from "react";

function SvgFaultReporting(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M15.285 12.305l-2.578-2.594a1 1 0 00-1.416-.002L9 12 4 7l2.294-2.294a1 1 0 00.001-1.414L3.715.708a1 1 0 00-1.415 0L.004 3.003 0 3c0 7.18 5.82 13 13 13l2.283-2.283a1 1 0 00.002-1.412zM16 8h-2c0-3.309-2.691-6-6-6V0c4.411 0 8 3.589 8 8z" />
        <path d="M12 8h-2c0-1.103-.897-2-2-2V4c2.206 0 4 1.794 4 4z" />
      </g>
    </svg>
  );
}

export default SvgFaultReporting;
