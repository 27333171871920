import * as React from "react";

function SvgBroadcasts(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        d="M3.5 4C1.6 4 0 5.6 0 7.5S1.6 11 3.5 11h.1l2.6 4.3c.4.7 1.3.9 2.1.5.7-.4.9-1.3.5-2.1L7.1 11H9l7 4V0L9 4H3.5z"
        fill="#F7654B"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgBroadcasts;
