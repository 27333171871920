import MailForm from './mail';

import SignupForm from './signup';

import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from '../../constants';

export default function CtaForm({ title }) {
	return (
		<div className="form-cta">
			<div className="container">
				<div className="form-cta__wrapper">
					<div className="form-cta__heading">
						<h2>{title || 'Power your facility with Artificial Intelligence'}</h2>
					</div>

					<div className="form-cta__content">
						<div className="form-cta__signup">
							<SignupForm
								btnText="sign up for a 14-day free trial"
								btnClass="btn btn--cta-signup"
								type={{ name: 'trial' }}
							/>

							<span className="form-cta__signup-desc">no credit card required</span>
						</div>

						<div className="form-cta__mail">
							<MailForm />

							<span className="form-cta__policy">
								<Policy />
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const Policy = () => {
	return (
		<>
			By submitting this form, you agree to our{' '}
			<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
				Privacy Policy
			</a>{' '}
			and{' '}
			<a href={TERMS_AND_CONDITION_LINK} target="_blank" rel="noreferrer">
				Terms & Conditions
			</a>
		</>
	);
};
