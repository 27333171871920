import { KnowledgeBase, ApiDocumentation, RoiCalculator, QuickSetup } from '../components/Svg';
import FontAwesomeIcon from '../components/FontAwesomeIcon';
import {
	KNOWLEDGE_BASE_LINK,
	API_DOCUMENTATION_LINK,
	BLOG_LINK,
	CASE_STUDIES_LINK,
	GLOSSARY_LINK,
	CANNY_WIDGET_LINK,
	STORE_LINK,
} from '../constants';
import { COMPETITORS } from './competitors';

export const RESOURCES = [];

export const RESOURCES_SUBMENU = [
	{
		link: BLOG_LINK,
		icon: <FontAwesomeIcon name={'fas fa-list'} />,
		name: 'Blog',
	},
	{
		link: KNOWLEDGE_BASE_LINK,
		icon: <KnowledgeBase />,
		name: 'KnowledgeBase',
	},
	{
		link: '/resources/academy',
		icon: <FontAwesomeIcon name={'fas fa-graduation-cap'} />,
		name: 'FacilityBot Academy',
	},
	{
		link: CASE_STUDIES_LINK,
		icon: <FontAwesomeIcon name={'fas fa-book-open'} />,
		name: 'Case Studies',
	},
	{
		link: API_DOCUMENTATION_LINK,
		icon: <ApiDocumentation />,
		name: 'API Documentation',
	},
	{
		link: '/resources/white-labelling',
		icon: <FontAwesomeIcon name={'fas fa-images'} />,
		name: 'White Labelling',
	},
	{
		link: '/resources/feature-requests',
		icon: <FontAwesomeIcon name={'fas fa-lightbulb'} />,
		name: 'Feature Requests',
	},
	{
		link: '/resources/roi-calculator',
		icon: <RoiCalculator />,
		name: 'ROI Calculator',
	},
	{
		link: '/resources/integrations',
		icon: <FontAwesomeIcon name={'fas fa-link'} />,
		name: 'Integrations',
	},
	{
		link: '/resources/referrals',
		icon: <FontAwesomeIcon name={'fas fa-hand-holding-usd'} />,
		name: 'Referral Programme',
	},
	{
		link: '/resources/quick-setup',
		icon: <QuickSetup />,
		name: 'Quick Set Up',
	},
	{
		link: '/resources/add-to-slack',
		icon: <FontAwesomeIcon name={'fab fa-slack-hash'} />,
		name: 'Add to Slack',
	},
	{
		link: '/resources/checklist-generator',
		icon: <FontAwesomeIcon name={'fas fa-magic'} />,
		name: 'Automated Checklist Generator',
	},
	{
		link: `${CANNY_WIDGET_LINK}/changelog`,
		icon: <FontAwesomeIcon name={'far fa-star'} />,
		name: 'ChangeLog',
	},
	{
		link: '/resources/channel-partner',
		icon: <FontAwesomeIcon name={'fas fa-user-friends'} />,
		name: 'Channel Partner Programme',
	},
	{
		link: '/resources/security-status',
		icon: <FontAwesomeIcon name={'fas fa-shield-alt'} />,
		name: 'Security Status',
	},
	{
		link: '/resources/checklist-library',
		icon: <FontAwesomeIcon name={'fas fa-tasks'} />,
		name: 'Checklist Library',
	},
	{
		link: GLOSSARY_LINK,
		icon: <FontAwesomeIcon name={'fas fa-book'} />,
		name: 'Glossary',
	},
	{
		link: '/resources/onboarding',
		icon: <FontAwesomeIcon name={'fas fa-handshake'} />,
		name: 'Onboarding',
	},
	{
		link: STORE_LINK,
		icon: <FontAwesomeIcon name={'fas fa-shopping-cart'} />,
		name: 'Store',
	},
	{
		link: '#',
		icon: (
			<div className="text-icon" style={{ color: 'var(--primary)' }}>
				VS
			</div>
		),
		name: 'FacilityBot VS',
		additional_submenu: COMPETITORS.map(({ slug, name }) => ({ slug, name })),
	},
];
