import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 31"}</title>
      <g transform="translate(-741 -1314)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={1314} width={55} height={55} rx={10} />
        <path
          d="M776.7 1338.3l-4-4c-.4-.4-1-.4-1.4 0l-1.3 1.3 1.7 1.7-1.4 1.4-1.7-1.7-1.6 1.6 1.7 1.7-1.4 1.4-1.7-1.7-1.6 1.6 1.7 1.7-1.4 1.4-1.7-1.7-1.3 1.3c-.4.4-.4 1 0 1.4l4 4c.4.4 1 .4 1.4 0l10-10c.4-.4.4-1 0-1.4z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
