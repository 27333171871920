export const FAQS = [
	{
		question: 'What type of users are considered paid users?',
		answer: `
      <p>The following users require paid accounts</p>
      <p>(1) Administrators ("Admin") and fault report center managers ("Manager") who need to configure the settings and see statistics</p>
      <p>(2) Technicians, cleaners, security officers, or any person who needs to respond to fault reports and other requests ("Responders")</p>
      <p>The following users do not require accounts</p>
      <p>(1) Users who make fault reports or requests ("Requestors")</p>
    `,
	},
	{
		question: 'How quickly can FacilityBot be implemented in my facility?',
		answer: `FacilityBot can be implemented almost immediately. Just go through the configuration steps, and it is ready to deploy in your facility. <a href="https://facilitybot.co/resources/quick-setup" target='_blank' rel='noreferrer'>In this Quick Set Up video</a>, we show how FacilityBot can be set up for Fault Reporting in just 3 minutes !</p>`,
	},
	{
		question: 'Can one FacilityBot subscription support multiple distributed locations?',
		answer:
			"Yes. FacilityBot's subscriptions are limited by the number of admin / manager / responder accounts rather than physical locations. FacilityBot's flexible location tagging approach also seamlessly supports distributed physical locations.",
	},
	{
		question: 'Can I request for new features in FacilityBot?',
		answer: `Yes, we welcome feature requests and suggestions for improvements. Please see our <a href="/resources/feature-requests" target='_blank' rel='noreferrer'>feature request page</a> for more information.`,
	},
	{
		question: 'Does FacilityBot support WhatsApp?',
		answer: 'Yes. WhatsApp is supported in the Professional and Plus plans.',
	},
	{
		question: 'Are there any set up costs?',
		answer: `Standard <a href="/resources/onboarding" target='_blank' rel='noreferrer'>Onboarding</a> is available without additional costs for all subscriptions. Enhanced <a href="/resources/onboarding" target='_blank' rel='noreferrer'>Onboarding</a> is available at a one-time cost for clients requiring enhanced support.`,
	},
	{
		question: 'What support would I have after subscription?',
		answer:
			'We offer chat support in all subscription plans. Our customer success team typically responds well within 1 hour to all chat requests.',
	},
	{
		question: 'Can I obtain my data at the end of the subscription?',
		answer:
			'FacilityBot allows you to export data to CSV for almost all data tables and lists. This can be done at any time so you need not wait for the end of the subscription to do so.',
	},
	{
		question: 'Are there any termination costs?',
		answer: 'No. That concept is foreign to us :)',
	},
	{
		question: 'Is my data secure?',
		answer: `We have implemented various cyber-security measures to keep your data secure. <a href="/resources/security-status" target='_blank' rel='noreferrer'>Here are the details</a>`,
	},
	{
		question:
			'I represent a Facilities Management Company. Is FacilityBot willing to partner with me to bid for a new tender / contract?',
		answer: `Yes, we are happy to partner with Facilities Management Companies to bid for new tenders / contracts, provided that the subscription is for at least 50 accounts if the tender / contract is won.`,
	},
	{
		question:
			'If I invite FacilityBot to participate in our ITQ / RFQ / RFP / Tender, will the subscription price remain as listed on the website?',
		answer: `Whilst being guided by our listed subscription price, our bid price might vary depending on the specific requirements of the ITQ / RFQ / RFP / Tender and the effort required to prepare the proposal.`,
	},
	{
		question:
			'I manage multiple properties on behalf of multiple clients. Each property should have its own asset register, checklists and requests. Is there a way for me to view data from multiple properties at once?',
		answer: `Yes, <a href="/features/property-portfolio" target='_blank' rel='noreferrer'>FacilityBot's property portfolio feature</a> is designed precisely for this use case`,
	},
	{
		question: 'Does FacilityBot support White Labelling?',
		answer: `FacilityBot allows clients to replace the FacilityBot logo in requestor / tenant facing channels. See <a href="/resources/white-labelling" target='_blank' rel='noreferrer'>FacilityBot's White Labelling</a> for more information.`,
	},
	{
		question: 'Can paid accounts be shared between multiple users?',
		answer: `Sharing of accounts is not permitted. However, in some cases, we understand that ground operations naturally lead to the sharing of accounts (e.g. cleaners sharing the same duty phone). In these cases, account sharing is permitted upon request.`,
	},
];
