import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={17}
      height={23}
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 8V1c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v7H0v8h16V8h-3zm-3 5H6v-2h4v2zm0-4H6V7h4v2zm0-4H6V3h4v2z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
