import * as React from "react"

function SvgExpenditures(props) {
  return (
    <svg width={12} height={16} viewBox="0 0 12 16" {...props}>
      <title>{"coins"}</title>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M6 6c2.9 0 6-.9 6-3S8.9 0 6 0 0 .9 0 3s3.1 3 6 3zM0 6.5V8c0 2.1 3.1 3 6 3s6-.9 6-3V6.5C10.6 7.4 8.5 8 6 8s-4.6-.6-6-1.5zM0 11.5V13c0 2.1 3.1 3 6 3s6-.9 6-3v-1.5c-1.4 1-3.5 1.5-6 1.5s-4.6-.6-6-1.5z" />
      </g>
    </svg>
  )
}

export default SvgExpenditures;
