import * as React from 'react';
import FontAwesomeIcon from '../../FontAwesomeIcon';

function SvgComponent() {
	return (
		<div className="feature__custom-icon">
			<FontAwesomeIcon name={'fas fa-history'} />
		</div>
	);
}

export default SvgComponent;
