import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 20"}</title>
      <g transform="translate(-101 -612)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={612} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M128 635.4v8.6h2v-8.6l4 4 1.4-1.4-5.7-5.7c-.4-.4-1-.4-1.4 0l-5.7 5.7 1.4 1.4 4-4z" />
          <path d="M135 646h-12v-3h-2v4c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-4h-2v3z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
