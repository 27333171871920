// * Static Data
// FontAwesomeIcon version: 5.15.4

import {
	FAULT_REPORTING,
	PREVENTIVE_MAINTENANCE_CHECKLIST,
	ASSET_MANAGEMENT,
	QR_CODE_SCANNING,
	PHOTO_AND_VIDEO_UPLOAD,
	STATISTICS,
	FACILITY_BOOKING,
	ONLINE_SURVEY,
	BROADCAST,
	AUTOMATED_FAQ,
	EXPENDITURES,
	CUSTOM_SERVICE_REQUESTS,
	MULTI_LANGUAGE,
	PROPERTY_PORTFOLIO,
	DOCUMENT_MANAGEMENT,
	VISITOR_MANAGEMENT,
	PROCUREMENT,
	DATA_ANALYTICS,
	CONFIGURABLE_WORKFLOWS,
	SCHEDULE_OF_RATES,
	IOT_SENSORS_INTEGRATION,
	METERS,
	PARTS,
	ATTENDANCE,
	VENDOR_MANAGEMENT,
	CUSTOM_DASHBOARD,
	MAP_DASHBOARD,
	BILLING,
	LICENSES,
	BUDGET,
	RESPONDER_LIVE_LOCATION,
	BMS_INTEGRATION,
	QR_CODE_FAULT_REPORTING,
	SENSOR_MAPS,
	TENANT_PORTAL,
	CUSTOMERS,
	PAYMENTS,
	SCHEDULING,
	VIRTUAL_DEMO,
	FIELD_SERVICE_MANAGEMENT,
	PERMIT_TO_WORK,
	BIM,
	CONTRACTS,
	DELIVERIES,
} from '../constants';
import {
	FaultReporting,
	PreventiveMaintenance,
	AssetManagement,
	QrCodeScanning,
	PhotoAndVideoEvidence,
	Statistics,
	FacilityAndRoomBooking,
	Surveys,
	Broadcasts,
	AutomatedFaqResponse,
	CustomServiceRequests,
	Expenditures,
	PropertyPortfolio,
	VisitorManagement,
	ConfigurableWorkflows,
	Hotdesks,
	Licenses,
	QrCodeFaultReporting,
	SensorMaps,
	TenantPortal,
	Payments,
	Delivery,
} from '../components/Svg';
import FontAwesomeIcon from '../components/FontAwesomeIcon';

export const FEATURE_GROUPS = [
	{
		name: 'Core Maintenance',
		color: '#e74c3c',
	},
	{
		name: 'Financial Features',
		color: '#3498db',
	},
	{
		name: 'Tenant Experience',
		color: '#34495e',
	},
	{
		name: 'Reporting and Integrations',
		color: '#2ecc71',
	},
];

export const FEATURES = [
	{
		submenu: {
			icon: <FaultReporting />,
			name: 'Fault Reporting',
			group: 1,
		},
		slug: 'fault-reporting',
		heading: {
			title: 'Fault Reporting',
			description: 'Make fault reporting as easy as humanly possible for fault reporters, technicians and managers.',
		},
		video_url: FAULT_REPORTING,
		articles: [
			{
				icon: 1,
				title: 'Use familiar channels',
				description:
					'Fault reporters use whatever channel is most convenient for them. Rather than compelling fault reporters to download new apps or use a selected channel, FacilityBot supports many channels to make things as easy as possible for fault reporters.',
			},
			{
				icon: 2,
				title: 'Technicians go mobile',
				description:
					'Fault responders such as technicians use the FacilityBot mobile app to communicate directly with fault reporters, update fault statuses, upload photos and videos and scan QR codes. No more filing paper reports or signing off on completion forms. FacilityBot makes things as simple as possible for all parties !',
			},
			{
				icon: 3,
				title: 'Automated workflows',
				description:
					'FacilityBot can assign faults to the party responsible for rectification based on fault types chosen by the Fault Reporter. For example, cleaning faults are automatically assigned to cleaners. Assigned parties can communicate directly with the fault reporter, update statuses and upload photos and videos. Dispense with manual co-ordination today !',
			},
			{
				icon: 4,
				title: 'Automated Priority Tagging',
				description:
					'FacilityBot uses Artificial Intelligence to automatically tag high priority incidents such as those involve personnel injury. For high priority faults, time is of essence. To reduce the time wasted for manual intervention, FacilityBot can automatically tag such incidents and alert the appropriate party to the incident.',
			},
		],
		plan: 'Unlimited Fault Reporting is included in every plan',
	},
	{
		submenu: {
			icon: <PreventiveMaintenance />,
			name: 'Preventive Maintenance',
			group: 1,
		},
		slug: 'preventive-maintenance',
		heading: {
			title: 'Preventive Maintenance',
			description:
				'Do away with paper checklists! Use FacilityBot as your Preventive Maintenance software to digitize all Preventive Maintenance schedules and tasks.',
		},
		video_url: PREVENTIVE_MAINTENANCE_CHECKLIST,
		articles: [
			{
				icon: 5,
				title: 'Easily create custom maintenance checklists',
				description:
					'FacilityBot has an in-built form-builder which allows you to build your custom checklists and schedule them to recur as needed. On the scheduled date, the checklist will appear in a "To-Do" list for the appropriate technician to view and complete.',
			},
			{
				icon: 6,
				title: 'Complete checklists on mobile devices',
				description:
					"Technicians can easily complete checklists using their web browsers, mobile tablets, or using the Facilitybot Mobile App. FacilityBot's cloud-based solution allows coordination of work by multiple parties on the move. Technicians can also attach photos and videos to the checklists as evidence of the checks.",
			},
			{
				icon: 7,
				title: 'Sign off on checklists digitally',
				description:
					'No more submission of paper checklists to be endorsed by supervisors ! With FacilityBot, managers and supervisors can also sign-off on preventive maintenance checklists digitally. All data is kept for audit purposes. Multiple levels of sign-off is also supported.',
			},
			{
				icon: 8,
				title: 'Plan Preventive Maintenance Schedules',
				description:
					'Enhance preventive maintenance planning using our calendar view. Upcoming scheduled maintenance activities can be sent to relevant parties to plan necessary equipment downtime and other logistics.',
			},
		],
		plan: 'Unlimited Preventive Maintenance checklists can be created in every plan',
	},
	{
		submenu: {
			icon: <AssetManagement />,
			name: 'Asset Management',
			group: 1,
		},
		slug: 'asset-management',
		heading: {
			title: 'Asset Management',
			description:
				'FacilityBot is a cloud-based asset management software that allows easy tracking of asset information and real-time linking of assets to requests and checklists to track asset maintenance history.',
		},
		video_url: ASSET_MANAGEMENT,
		articles: [
			{
				icon: 9,
				title: 'Easily import assets',
				description:
					'Assets can be easily imported in bulk at initial set up. Once imported, FacilityBot can help track the maintenance history of the asset.',
			},
			{
				icon: 10,
				title: 'Link fault reports and PM checklists',
				description:
					'All requests, fault reports and preventive maintenance checklists can be readily linked to assets. Having been linked, the progress of the request ticket or preventive maintenance checklist can be easily viewed from the Asset perspective.',
			},
			{
				icon: 11,
				title: 'Group assets',
				description: 'Assets can be grouped to show the maintenance history of a group of assets at a glance.',
			},
			{
				icon: 12,
				title: 'Easily conduct asset checks',
				description:
					'With FacilityBot, asset checks can be conducted on the move. Using the FacilityBot Mobile App, simply scan the QR code of the Asset ID and quickly view the maintenance history of the asset, including fault reports and preventive maintenance checklists.',
			},
		],
		plan: "Unlimited assets can be created with FacilityBot's Asset Management System",
	},
	{
		submenu: {
			icon: <QrCodeScanning />,
			name: 'Asset QR Code Scanning',
			group: 1,
		},
		slug: 'qr-code-scanning',
		heading: {
			title: 'Asset QR Code Scanning',
			description: 'The QR Code scanner in the Facility Mobile App allows quick interaction with assets',
		},
		video_url: QR_CODE_SCANNING,
		articles: [
			{
				icon: 13,
				title: 'Link assets on the move',
				description:
					'The FacilityBot Mobile App allows linking of assets to fault reports and requests by scanning of the asset QR code. This allows technicians to accurately identify the asset they are attending to. Sometimes, this can be used as evidence of technicians arriving at the location of the fault.',
			},
			{
				icon: 14,
				title: 'View asset maintenance history on the move',
				description:
					"The Asset QR Code can also be used as a quick way to identify the Asset in question to view it's maintenance history. Simply use the Facility Mobile App to scan the Asset QR Code.",
			},
		],
		plan: 'Unlimited QR Code scanning can be performed in every plan',
	},
	{
		submenu: {
			icon: <PhotoAndVideoEvidence />,
			name: 'Photo and Video Evidence',
			group: 1,
		},
		slug: 'photo-and-video-evidence',
		heading: {
			title: 'Photo and Video Evidence',
			description: 'Exercise fault rectification oversight. Close the loop with fault reporters. Satisfy the auditors.',
		},
		video_url: PHOTO_AND_VIDEO_UPLOAD,
		articles: [
			{
				icon: 15,
				title: 'Fault rectification oversight',
				description:
					'FacilityBot can require photo evidence to be attached before a fault ticket can be closed. Managers can therefore easily exercise oversight that all faults have been properly rectified.',
			},
			{
				icon: 16,
				title: 'Send rectification evidence to fault reporters',
				description:
					'FacilityBot automatically updates the fault reporters on the status of their fault report. If fault rectification photos are captured, this can also be automatically sent to the fault reporter to close the loop. What a great way to delight building users !',
			},
			{
				icon: 17,
				title: 'Maintain evidence for audits',
				description:
					'No more worries when the auditors visit. FacilityBot maintains records of all fault tickets including any rectification photo and video evidence. This information is easily searchable to show to the auditors.',
			},
			{
				icon: 18,
				title: 'Mobile enabled',
				description:
					'The FacilityBot Mobile App allows photo and video attachments too. This allows technicians to complete the fault rectification and record evidence of such rectification on site in a single digital interaction.',
			},
		],
		plan: 'Photo and Video Evidence is included in every plan',
	},
	{
		submenu: {
			icon: <Statistics />,
			name: 'Statistics, Audit Trails and KPIs',
			group: 4,
		},
		slug: 'statistics',
		heading: {
			title: 'Statistics, Audit Trails and KPIs',
			description: 'All data recorded, retained and presented in easy to read formats',
		},
		video_url: STATISTICS,
		articles: [
			{
				icon: 19,
				title: 'Record response and rectification times',
				description:
					'FacilityBot will automatically capture the response and rectification times of all fault reports and requests. This allows easy management tracking of the status of all fault reports and how quickly they were attended to. After all, what gets measured gets done.',
			},
			{
				icon: 20,
				title: 'View histories of all request tickets',
				description:
					'FacilityBot captures who did what, when. This information becomes very useful to analyse any reasons for delay in particular request tickets. FacilityBot can also show chat information between fault reporters and technicians, providing further colour to the fault rectification history.',
			},
			{
				icon: 21,
				title: 'Measure against KPI thresholds',
				description:
					'FacilityBot allows the creation of KPI thresholds for each type of fault and each level of priority. Having set these KPI thresholds, FacilityBot will display the monthly statistics for the faults that met or exceeded the specified thresholds.',
			},
			{
				icon: 22,
				title: 'Export for further processing',
				description:
					'Most data in FacilityBot can be exported for further processing. Easily filter or search for the data in FacilityBot and export what you see to incorporate the data in your custom report.',
			},
		],
		plan: 'Statistics, Audit Trails and KPIs are included in every plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-clipboard-check'} />,
			name: 'Attendance',
			group: 1,
		},
		slug: 'attendance',
		heading: {
			title: 'Attendance',
			description: 'Allow Responders to check in and out at sites via mobile apps',
		},
		video_url: ATTENDANCE,
		articles: [
			{
				icon: 16,
				title: 'Easily create new sites and specify GPS coordinates',
				description:
					"FacilityBot's Attendance feature allows you to easily create new sites at which you would like to track Responder check-ins and check-outs. Simply enter the GPS coordinates of the site and the radius around which check-ins are valid.",
			},
			{
				icon: 2,
				title: 'Responders check-in and check-out using their mobile app',
				description:
					'Once sites are configured, Responders can check in and check out easily using the FacilityBot mobile app. Responders can also be required to capture an image at the point of check-in. FacilityBot automatically tracks and collates data for all check-ins and check-outs.',
			},
		],
		plan: 'The Attendance feature is available with Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-map-marker-alt'} />,
			name: 'Responder Live Location',
			group: 1,
		},
		slug: 'responder-live-location',
		heading: {
			title: 'Responder Live Location',
			description: 'View the Live Location of all Responders',
		},
		video_url: RESPONDER_LIVE_LOCATION,
		articles: [
			{
				icon: 17,
				title: 'Easy Set Up',
				description:
					'Simply toggle this feature "on", and ask Responders to allow the location tracking for their FacilityBot mobile app, and this feature is ready to go!',
			},
			{
				icon: 34,
				title: 'View Responder Locations on a Map',
				description:
					'Easily see where all the Responders are with respect to your site. Use this information to gauge manpower adequacy or gauge response times to requests.',
			},
		],
		plan: 'The Responder Live Location feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-calendar-week'} />,
			name: 'Schedules',
			group: 1,
		},
		slug: 'schedules',
		heading: {
			title: 'Scheduling',
			description: 'Digitize your field service operations by scheduling daily tasks for technicians',
		},
		video_url: SCHEDULING,
		articles: [
			{
				icon: 8,
				title: 'Drag and Drop Tasks onto Responders Calendars',
				description:
					'With FacilityBot, scheduling tasks for your field service technicians (Responders) could not be easier. Simply drag and drop Requests or Checklists ("Tasks") onto the calendar of the Responder, move the task to another time to reschedule, or move the task to another Responder to reassign.',
			},
			{
				icon: 2,
				title: 'Technicians complete tasks using the FacilityBot mobile app',
				description:
					'Once scheduled, technicians will know which sites they need to attend to and be able to complete the Requests or Checklists ("Tasks") using the FacilityBot mobile app. Admins and managers will be able to see task completion in real-time as technicians complete them.  ',
			},
		],
		plan: 'The Scheduling feature is available with the Professional and Plus plans ',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-hard-hat'} />,
			name: 'Permit to Work',
			group: 1,
		},
		slug: 'permit-to-work',
		heading: {
			title: 'Permit to Work',
			description: 'Digitize your Permit to Work application and approval process',
		},
		video_url: PERMIT_TO_WORK,
		articles: [
			{
				icon: 5,
				title: 'Easily configure checklists and approval process flows',
				description:
					'Easily create new Permit Types by <a href="https://facilitybot.co/features/preventive-maintenance" target="_blank" rel="noreferrer">configuring checklists</a> and the approval process flow for the Permit. For each step of the approval process, you can specify the person that should be assigned.',
			},
			{
				icon: 49,
				title: 'Invite contractors to apply for Permits and manage approval process',
				description:
					'Trigger invitation emails to Contractors to apply for Permits. Contractors submit their application through a webform and receive a permit application ID. Internally, the appropriate person can be automatically assigned for each step of the approval process and sign off digitally.  Applicants will be automatically updated for each status update of their application.',
			},
		],
		plan: 'The Permit to Work feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <TenantPortal />,
			name: 'Tenant Portal',
			group: 3,
		},
		slug: 'tenant-portal',
		heading: {
			title: 'Tenant Portal',
			description: 'Customize the FacilityBot Sign In Page to become a Tenant Portal',
		},
		video_url: TENANT_PORTAL,
		articles: [
			{
				icon: 23,
				title: 'Set your very own FacilityBot Subdomain',
				description:
					'FacilityBot allows our clients to have their very own subdomain. Simply enter your desired unique subdomain in Features -> Tenant Portal. If it is available, you can use that as the sign in page for your tenants.',
			},
			{
				icon: 24,
				title: 'Add custom text, images, links or html to the Tenant Portal',
				description:
					'FacilityBot allows easy configuration of your Tenant Portal with a WYSIWYG (What-you-see-is-what-you-get) editor. Write custom text or add images or links to your sign-in page. Or add HTML directly (HINT: you can use ChatGPT to create HTML for you).',
			},
		],
		plan: 'The Tenant Portal feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FacilityAndRoomBooking />,
			name: 'Facility and Room Booking',
			group: 3,
		},
		slug: 'facility-and-room-booking',
		heading: {
			title: 'Facility and Room Booking',
			description:
				'Use FacilityBot as your room booking system. Allow building occupants to easily book rooms or common facilities through their preferred messaging app.',
		},
		video_url: FACILITY_BOOKING,
		articles: [
			{
				icon: 23,
				title: 'Easily set up meeting rooms, conference rooms, or other common facilities',
				description:
					'Easily create rooms or common facilities of any type in the FacilityBot web portal, including setting availability periods and images of the room or facility.',
			},
			{
				icon: 24,
				title: 'Multiple ways for users to book',
				description:
					'Users can book using the channel that they are most familiar with, including WhatsApp, Telegram, Facebook Messenger, Facebook Workplace or a web browser. Simplify the process and reduce the friction for your users by doing away with app downloads and logins.',
			},
			{
				icon: 25,
				title: 'Works for item lending too',
				description:
					'Since rooms / facilities are defined broadly as anything that can be booked, this feature works well for item or equipment loan also. For example, cars and computers can be defined as "facilities" and booked in the same way as rooms, tennis courts or barbecue pits can be booked.',
			},
			{
				icon: 26,
				title: 'View real-time availability',
				description:
					'Users will immediately see the availability of the room or facility that they wish to book when they choose their desired date. Room managers can also view bookings and availabilities in real-time.',
			},
		],
		plan: 'Unlimited Facility and Room Booking is available with Professional and Plus plans',
	},
	{
		submenu: {
			icon: <Surveys />,
			name: 'Surveys',
			group: 3,
		},
		slug: 'surveys',
		heading: {
			title: 'Surveys',
			description: 'Facility management is more than just fault response. Engage building users using surveys.',
		},
		video_url: ONLINE_SURVEY,
		articles: [
			{
				icon: 27,
				title: 'Easily create custom surveys',
				description:
					'Easily configure new surveys using our in-built form builder. Whether to decide on upcoming facility enhancements, or to determine current satisfaction, surveys are a great tool to engage your building users.',
			},
			{
				icon: 28,
				title: 'Broadcast the survey',
				description:
					'FacilityBot allows the easy broadcast of the surveys through the same channel in which the user first communicated with FacilityBot. Enhance survey response rates in the most natural and user friendly way. FacilityBot also allows survey broadcast to selected sub-segment by creating "campaigns".',
			},
			{
				icon: 29,
				title: 'Results are automatically collated',
				description:
					'Once survey respondents fill up the form, the data will be automatically collated and presented in both tabular and graphical form on the FacilityBot web portal.',
			},
			{
				icon: 30,
				title: 'Supports multiple surveys',
				description:
					'Run multiple surveys at different times, or even concurrently. These are all possible using the FacilityBot survey feature.',
			},
		],
		plan: 'Unlimited Surveys can be created in every plan',
	},
	{
		submenu: {
			icon: <Broadcasts />,
			name: 'Broadcasts',
			group: 3,
		},
		slug: 'broadcasts',
		heading: {
			title: 'Broadcasts',
			description: 'Send messages to building users. Keep them updated of Facilities Management activities',
		},
		video_url: BROADCAST,
		articles: [
			{
				icon: 31,
				title: 'Communicate with Broadcasts',
				description:
					'We understand that Facility Management is not only about responding to requests by building users; it is about communicating with building users. This is why FacilityBot allows facilities management to send broadcasts to building users, whether this is about fire drills, routine maintenance closures or any other ad-hoc announcements.',
			},
			{
				icon: 32,
				title: 'Create broadcast "campaigns"',
				description:
					'FacilityBot allows the broadcasts to different user segments, including defining custom groups of building users as "campaigns". In all cases, broadcasts will be sent through the same channels that the building user used to first communicate with FacilityBot. Keep communicating with building users using a single channel to achieve the best view rates.',
			},
		],
		plan: 'Unlimited Broadcasts can be made in every plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-list'} />,
			name: 'Schedule of Rates',
			group: 2,
		},
		slug: 'schedule-of-rates',
		heading: {
			title: 'Schedule of Rates',
			description:
				'FacilityBot helps you work better with agreed prices from your pre-approved vendors. Vendors easily quote items of work from the Schedule of Rates and FacilityBot helps you manage quote approvals and track expenditures from each Schedule of Rates.',
		},
		video_url: SCHEDULE_OF_RATES,
		articles: [
			{
				icon: 54,
				title: 'Easily configure Schedules of Rates',
				description:
					'As a Facility Manager, you may have already agreed on a list of prices with your pre-approved vendors. FacilityBot allows you to easily import these prices. When the pre-approved Vendor is assigned to Requests, he can then select the appropriate line item and quantity as a quick quote.',
			},
			{
				icon: 55,
				title: 'Automate workflows when quotes are submitted',
				description:
					'FacilityBot can automate workflows after pre-approved vendors submit their quotes from the Schedule of Rates. Expenditure requests can be automatically emailed to approvers based on cost thresholds. Expenditure data from each Schedule of Rates can also be analyzed.',
			},
		],
		plan: 'The Schedule of Rates Feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <AutomatedFaqResponse />,
			name: 'Automated FAQ Response',
			group: 3,
		},
		slug: 'automated-faq-response',
		heading: {
			title: 'Automated FAQ Response',
			description:
				'Let FacilityBot handle FAQs automatically. Free up management and technicians to perform higher value activities.',
		},
		video_url: AUTOMATED_FAQ,
		articles: [
			{
				icon: 33,
				title: 'In-built Natural Language Processor',
				description:
					'FacilityBot comes integrated with an Artificial-Intelligence powered Natural Language Processor. This allows FacilityBot to parse the intent of questions sent by users, and provide answers that had been configured via the FacilityBot web portal.',
			},
			{
				icon: 34,
				title: 'Unlimited FAQs',
				description:
					'There is no limit on the number of FAQs that can be populated. This means that you can leave FacilityBot to answer questions from building users such as opening hours, directions and much more !',
			},
			{
				icon: 35,
				title: 'Respond with Images and Links',
				description:
					'We understand that some chatbot responses require images, links or even links to other FAQs to continue the conversation. All these are easily configured in FacilityBot.',
			},
			{
				icon: 36,
				title: 'Find out what building users want to know',
				description:
					'FacilityBot will gather questions that it was not able to understand. In this way, Facilities Managers can quickly decide what new answers to FAQs should be configured. As more FAQ responses are provided, FacilityBot will be able to handle a greater portion of user queries automatically.',
			},
		],
		plan: 'Unlimited FAQs can be created in every plan',
	},
	{
		submenu: {
			icon: <CustomServiceRequests />,
			name: 'Custom Service Requests',
			group: 1,
		},
		slug: 'custom-service-requests',
		heading: {
			title: 'Custom Service Requests',
			description:
				"Dealing with Events Set Up? Security Pass Requests? FacilityBot's custom service request feature can handle almost any type of request. FacilityBot is the single platform for all your Facility Management needs.",
		},
		video_url: CUSTOM_SERVICE_REQUESTS,
		articles: [
			{
				icon: 41,
				title: 'Configurable Request Forms',
				description:
					"FacilityBot's form builder allows you to configure forms for each Service Request type. So you can easily create forms for Event Set Up, Security Pass Requests, Car Park Label requests, or any other service request that your facility management needs to handle",
			},
			{
				icon: 42,
				title: 'Integrated Workflows',
				description:
					'Once configured, custom service requests go through the same workflow as fault reports, including the ability to set priorities, location tags, respond with images, create expenditure requests etc. The consistent approach makes it super easy for managers and technicians to use FacilityBot',
			},
			{
				icon: 43,
				title: 'Omni-Channel Request Options',
				description:
					'Once configured, requestors can make service requests through all the channels that they can make Fault Reports. Whether through the supported messaging platforms or the FacilityBot Mobile App, it cannot be easier for requestors to communicate with their Facilities Manager and be assured that their requests are well taken care of',
			},
			{
				icon: 44,
				title: 'Statistics of Custom Requests',
				description:
					'As with all other features, statistics of custom requests are captured and displayed in a useful and easy to interpret way. Whether you want to know the details of each request, or aggregate statistics broken down by location, request type or time-of-day, all the statistics are just a few clicks away',
			},
		],
		plan: 'Unlimited custom Service Request Types can be created in every plan',
	},
	{
		submenu: {
			icon: <Expenditures />,
			name: 'Expenditures',
			group: 2,
		},
		slug: 'expenditures',
		heading: {
			title: 'Expenditures',
			description:
				"Each fault report or service request may entail new expenditures. FacilityBot's expenditure feature will help you handle that.",
		},
		video_url: EXPENDITURES,
		articles: [
			{
				icon: 37,
				title: 'Create Expenditure Requests',
				description:
					'We understand that additional expenditures may be required for certain fault rectification or service request fulfillment. With FacilityBot, facilities managements can easily create new expenditure requests, attach supporting quotes and set Cost Centres',
			},
			{
				icon: 38,
				title: 'Request Approval for Expenditures',
				description:
					'FacilityBot allows expenditures requests can be sent for approval. Emails will automatically be sent to specified approvers who can approve expenditures with a click of a button in their email. The expenditure request and approval history is captured to facilitate audit compliance',
			},
			{
				icon: 39,
				title: 'Track Overall Expenditures',
				description:
					'To help keep expenditures within control, we offer quick views of overall facilities management expenditures and also budget setting and alerts if expenditures exveed pre-set thresholds',
			},
			{
				icon: 40,
				title: 'Expenditure Statistics',
				description:
					'FacilityBot displays expenditure statistics broken down in useful ways such including location tags, request types and cost centres. Easily manage your facility management related expenditures using FacilityBot',
			},
		],
		plan: 'Unlimited Expenditure Requests and Approvals can be created in every plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-globe-asia'} />,
			name: 'Multi-Language',
			group: 3,
		},
		slug: 'multi-language',
		heading: {
			title: 'Multi-Language',
			description:
				'FacilityBot understands 109 languages ! Are some of your users more comfortable in languages besides English? No problem, FacilityBot can chat with Requestors in their preferred language.',
		},
		video_url: MULTI_LANGUAGE,
		articles: [
			{
				icon: 45,
				title: 'Set Up in English. Chat in any language',
				description:
					"Configure the messages that FacilityBot should reply with in English and let FacilityBot do the translation to any of the 109 languages. Simple set up, powerful features. That is FacilityBot's approach.",
			},
			{
				icon: 46,
				title: 'Supports all Messaging Channels',
				description:
					'Multi-Language support is available through all chat channels through which Requestors can communicate with FacilityBot. Requestors simply chat in their preferred language, FacilityBot will automatically detect the language and reply in the same language.',
			},
		],
		plan: 'Multi-Language support is available in every plan',
	},
	{
		submenu: {
			icon: <PropertyPortfolio />,
			name: 'Property Portfolio',
			group: 4,
		},
		slug: 'property-portfolio',
		heading: {
			title: 'Property Portfolio',
			description:
				"Managing multiple properties and looking for a solution that can aggregate data from all properties? No problem. FacilityBot's Portfolio feature helps you do just that",
		},
		video_url: PROPERTY_PORTFOLIO,
		articles: [
			{
				icon: 47,
				title: 'See data from all properties at a glance',
				description:
					"We understand most facility managers do not only manage a single property. Even as FacilityBot works well for each individual property, centrally, there is a desire to aggregate all data for visualization, analysis and reporting. FacilityBot's Property Portfolio feature helps management do just that.",
			},
			{
				icon: 48,
				title: 'Easily link or unlink FacilityBot instances',
				description:
					"FacilityBot's Property Portfolio feature allows you to easily link new FacilityBot instances and take away old instances. You can also choose to include or exclude data from selected instances, allowing easy comparison of performance across different properties.",
			},
		],
		plan: `<p>The Property Portfolio feature is available for any client with more than 1 FacilityBot instance</p>
		<p>Each Portfolio Admin account is available for USD 200 per month (paid annually in advance)</p>
		`,
	},
	{
		submenu: {
			icon: <VisitorManagement />,
			name: 'Visitor Management',
			group: 3,
		},
		slug: 'visitor-management',
		heading: {
			title: 'Visitor Management',
			description:
				'FacilityBot can act as your cloud-based Visitor Management software. Our Visitor Management feature allows hosts to easily invite guests through their preferred messaging channel.',
		},
		video_url: VISITOR_MANAGEMENT,
		articles: [
			{
				icon: 49,
				title: 'Hosts Easily Invite Guests',
				description:
					'Hosts use their preferred messaging channels to enter their guest information. An official email invite will be sent to their guest with a Visit ID. Guests show their official email invite to evidence that they are authorised to enter the building.',
			},
			{
				icon: 50,
				title: 'Configurable Guest Information Form',
				description:
					'Configure the information that hosts need to include to invite their guest. All necessary visitor registration information can be captured beforehand. Visitor records are also stored.',
			},
			{
				icon: 51,
				title: 'Configurable Email Message',
				description:
					'Configure the email that will be sent to guests to invite them to your building. Easily add a google map, additional instructions that you would like to provide to your guests before arrival or even a QR code that guests can scan to facilitate self-entry into the building.',
			},
			{
				icon: 52,
				title: 'Reduce Front-End Reception Work. Enhance Security and Visitor Experience',
				description:
					'With the official email invite, all relevant guest information captured before the visit and even self-entry, reception work is greatly reduced. At the same time, since all visitors can evidence their invite using the official email invite, security is also enhanced.',
			},
		],
		plan: "FacilityBot's Visitor Management solution is available with Professional and Plus plans",
	},
	{
		submenu: {
			icon: <Delivery />,
			name: 'Deliveries',
			group: 3,
		},
		slug: 'deliveries',
		heading: {
			title: 'Deliveries',
			description: 'Track Mail and Packages received.',
		},
		video_url: DELIVERIES,
		articles: [
			{
				icon: 49,
				title: 'Easily Register New Deliveries and Alert Recipients',
				description:
					'Whether food deliveries, mail, parcels, or packages, new deliveries can be easily created through the FacilityBot web portal. Recipients can be alerted via email or through their messaging apps.',
			},
			{
				icon: 50,
				title: 'Recipients Sign Off to Mark Deliveries as Picked Up',
				description:
					'Recipients receive a link through which they can sign off to mark their delivery as Picked Up. Automatic reminders can be sent to recipients who have not picked up their deliveries.',
			},
		],
		plan: 'The Deliveries feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-folder-open'} />,
			name: 'Document Management',
			group: 1,
		},
		slug: 'document-management',
		heading: {
			title: 'Document Management',
			description: 'Store and organize your asset warranties, manuals and other documents',
		},
		video_url: DOCUMENT_MANAGEMENT,
		articles: [
			{
				icon: 29,
				title: 'Organize your Asset Documents',
				description:
					'Use FacilityBot to store softcopies of all your facility related documents, whether these are warranties, manuals or other documents, organized based on Assets for easy search and retrieval. Never go through the hassle of searching for asset-related hardcopy documents again !',
			},
			{
				icon: 15,
				title: 'Capture Asset Notes',
				description:
					'FacilityBot allows the capturing of ad-hoc asset notes. Therefore, FacilityBot allows you to store asset management notes, documents and maintenance records all on a single platform. Just like medical records for people, asset records should all be on a single platform in order to easily diagnose any issues related the asset.',
			},
		],
		plan: 'Document Management is available in every plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-store'} />,
			name: 'Procurement',
			group: 2,
		},
		slug: 'procurement',
		heading: {
			title: 'Procurement',
			description: 'Automate your procurement process, enhance transparency and gather data',
		},
		video_url: PROCUREMENT,
		articles: [
			{
				icon: 53,
				title: 'Easily invite vendors to quote',
				description:
					'Easily set up new Request for Quotes (RFQs), select vendors and FacilityBot will handle the rest ! Emails will be sent to selected vendors to invite them to quote and you can see the quotes arrive as they are submitted through FacilityBot.',
			},
			{
				icon: 52,
				title: 'Transparency and confidentiality',
				description:
					"Transparency in procurement is important to all Facilities Management operations. FacilityBot's procurement feature is designed to act as a Single Source of Truth for procurement activities, while also taking into consideration the need for Confidentiality in the procurement process.",
			},
			{
				icon: 9,
				title: "Use FacilityBot's vendor list or add your own",
				description:
					'FacilityBot maintains vendor lists in targeted countries to save you the hassle of finding suitable vendors. Of course, we allow you to add your preferred vendors to the list too.',
			},
			{
				icon: 47,
				title: 'Gather and correlate rich data',
				description:
					"FacilityBot's procurement feature adds to the rich array of features already available, and allows greater correlation of data across various Facilities Management activities.",
			},
		],
		plan: 'The Procurement feature is available in every plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-chart-pie'} />,
			name: 'Data Analytics',
			group: 4,
		},
		slug: 'data-analytics',
		heading: {
			title: 'Data Analytics',
			description:
				'Is putting together reports taking up too much of your time? Use FacilityBot to eliminate manual reporting tasks',
		},
		video_url: DATA_ANALYTICS,
		articles: [
			{
				icon: 22,
				title: 'Easily configure and share reports',
				description:
					"Too much of a Facilities Managers time is spent downloading and collating data, formatting it into a report and then sending it to management. With FacilityBot's data analytics feature, you can easily configure tables, charts and dashboards as required by management. Management can view the report at any time simply using web link.",
			},
			{
				icon: 40,
				title: 'Make better data-driven decisions',
				description:
					'As long as data is captured in FacilityBot, including request, response time, asset, building user feedback and expenditures data, it can be presented in a way to best help building owners make decisions. Too often, due to inefficient data collation and analytics processes, building owners have to rely on anecdotal evidence for decision-making. FacilityBot makes data collation and analytics a breeze !',
			},
		],
		plan: 'The data analytics integration is available FREE with any subscription plan (for a limited time).',
	},
	{
		submenu: {
			icon: <ConfigurableWorkflows />,
			name: 'Configurable Workflows',
			group: 1,
		},
		slug: 'configurable-workflows',
		heading: {
			title: 'Configurable Workflows',
			description:
				"At FacilityBot, we understand Facilities Management processes differ for each building. That's why we allow configurable workflows to support your specific process.",
		},
		video_url: CONFIGURABLE_WORKFLOWS,
		articles: [
			{
				icon: 19,
				title: 'Automate Alerts and Assignments',
				description:
					'FacilityBot aims to avoid manual intervention where possible and to reduce the co-ordination work of the fault report centre. Requests can be automatically assigned and relevant people alerted through Email and SMS based on request attributes.',
			},
			{
				icon: 3,
				title: 'Automate Reminders, Re-Assignments and Escalations',
				description:
					'Workflows can be configured to handle requests that have not been dealt with. Reminders can be sent to the persons originally assigned, or these request can be automatically re-assigned or escalated to other personnel after a specified period of time.',
			},
		],
		plan: 'The Configurable Workflows Feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-assistive-listening-systems'} />,
			name: 'IOT Sensors Integration',
			group: 4,
		},
		slug: 'iot-sensors-integration',
		heading: {
			title: 'IOT Sensors Integration',
			description:
				'IOT sensors integration with CMMS systems need not be difficult. FacilityBot makes it super easy for you to achieve Smart Facilities Management outcomes.',
		},
		video_url: IOT_SENSORS_INTEGRATION,
		articles: [
			{
				icon: 1,
				title: 'Easily send IOT data through MQTT',
				description:
					"With FacilityBot, you can create sensors of any type. Once set up, configure the IOT sensor to send data to our MQTT broker with FacilityBot's unique sensor ID. That's all it takes. Integrations can literally be set up in minutes.",
			},
			{
				icon: 3,
				title: 'Configure IOT sensor trigger workflows including auto-assignment',
				description:
					'FacilityBot allows you to easily configure trigger thresholds to automatically create request tickets based on IOT sensor data. For example, within minutes, you can set up an ammonia sensor to create a request ticket that is assigned to a cleaner, with no human intervention.',
			},
		],
		plan: 'IOT Sensor Integrations are available with Professional and Plus plans',
	},
	{
		submenu: {
			icon: <SensorMaps />,
			name: 'Sensor Maps',
			group: 4,
		},
		slug: 'sensor-maps',
		heading: {
			title: 'Sensor Maps',
			description: 'View the condition of Sensors on a Floor Plan.',
		},
		video_url: SENSOR_MAPS,
		articles: [
			{
				icon: 17,
				title: 'Easy Setup',
				description:
					'Setting up Sensor Maps cannot be easier. Simply upload your floor plan and place the sensors as dots on the floor plan. Set the conditions and the colour of the sensor dot will change depending on the last set of data received from the Sensor.',
			},
			{
				icon: 57,
				title: 'Share or Display the Sensor Maps on Screens',
				description:
					'Once the Sensor Maps have been set up, you can obtain a URL for the webview which can be shared with anyone wanting to visualize the latest condition of the sensors displayed on a floor plan. The webview can also be displayed within monitors in the operations center, office reception, or lobby areas.',
			},
		],
		plan: 'The Sensor Maps feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-city'} />,
			name: 'BIM Integration',
			group: 4,
		},
		slug: 'bim-integration',
		heading: {
			title: 'BIM Integration',
			description: 'Import your Building Information Model (BIM) into FacilityBot and include Tags.',
		},
		video_url: BIM,
		articles: [
			{
				icon: 17,
				title: 'Import Building Information Model',
				description:
					'FacilityBot allows easy importing of your 3D BIM model (using IFC format). Once imported, admins and managers can explore and navigate through the BIM within FacilityBot.',
			},
			{
				icon: 30,
				title: 'Tag your BIM',
				description: 'Easily include Tags in the BIM model to identify locations and assets of interest.',
			},
		],
		plan: 'The BIM Integration feature is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-tachometer-alt'} />,
			name: 'Meters',
			group: 1,
		},
		slug: 'meters',
		heading: {
			title: 'Meters',
			description: 'FacilityBot helps you track energy, utilities and equipment usage',
		},
		video_url: METERS,
		articles: [
			{
				icon: 17,
				title: 'Easily create new meters of any type',
				description:
					"FacilityBot's Meters feature allows you to create meters of any type including energy, water, and equipment usage meters. Meters can be tagged with location tags, to identify the location of the meter, and linked to assets, to be able to view meter data from the asset perspective.",
			},
			{
				icon: 44,
				title: 'Record meter readings and track aggregated data',
				description:
					'Readings for each meter can be easily entered in FacilityBot. Meter data will be automatically aggregated to allow easy analysis of cumulative and average data.',
			},
		],
		plan: 'The Meters feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-puzzle-piece'} />,
			name: 'Parts',
			group: 1,
		},
		slug: 'parts',
		heading: {
			title: 'Parts',
			description: 'Easily track your inventory of spare parts',
		},
		video_url: PARTS,
		articles: [
			{
				icon: 10,
				title: 'Easily create new parts and link them to assets',
				description:
					"FacilityBot's Parts feature allows you to easily create new parts with a starting balance and then link the part to an asset.",
			},
			{
				icon: 8,
				title: 'Record changes in quantities of spare parts',
				description:
					'Changes in the number of spare parts can be easily tracked. Record additions to parts when you purchase new parts. Record deductions in parts when they are used up. Link quantity changes to requests to know the underlying request which used up the spare part.',
			},
		],
		plan: 'The Parts feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'far fa-building'} />,
			name: 'BMS Integration',
			group: 4,
		},
		slug: 'bms-integration',
		heading: {
			title: 'BMS Integration',
			description: 'FacilityBot can integrate with BMS systems using our proprietary BACNet Gateway',
		},
		video_url: BMS_INTEGRATION,
		articles: [
			{
				icon: 22,
				title: "Extract data using FacilityBot's BACNet Gateway",
				description:
					'FacilityBot has developed a proprietary BACNet gateway that can connect to the BMS Systems using BACNet via ethernet. The gateway can be easily configured to select the appropriate BMS device, object, and property that should be extracted and sent to FacilityBot.',
			},
			{
				icon: 3,
				title: 'Extract BMS data to populate meters or automatically trigger workflows',
				description:
					'With this integration, data from BMS systems can be used to populate meter readings in FacilityBot, or automatically trigger request tickets when the BMS system data exceeds specified thresholds. Technicians will be automatically alerted via the FacilityBot Mobile App and can quickly attend to the case.',
			},
		],
		plan: 'BMS Integration is available with the Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-columns'} />,
			name: 'Custom Dashboard',
			group: 4,
		},
		slug: 'custom-dashboard',
		heading: {
			title: 'Custom Dashboard',
			description:
				'Never worry about reporting again. Configure a custom dashboard by selecting from various charts and tables within FacilityBot',
		},
		video_url: CUSTOM_DASHBOARD,
		articles: [
			{
				icon: 40,
				title: 'Easy Dashboard Set Up',
				description:
					"Select available chart and table widgets to add to your dashboard. Drag and drop them to position these widgets in your dashboard. If needed, set custom filters and parameters to display filtered data. That's all. Set up could not be simpler.",
			},
			{
				icon: 22,
				title: 'Easily Share Real-Time Dashboards',
				description:
					'Share the dashboard URL with anyone who needs to view the dashboard. All charts and tables in the dashboard, with data updated in real-time, can then be accessed through any browser.',
			},
		],
		plan: 'The Custom Dashboard Feature is available with Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'far fa-map'} />,
			name: 'Map Dashboard',
			group: 4,
		},
		slug: 'map-dashboard',
		heading: {
			title: 'Map Dashboard',
			description: "View Requests, Sensors, Responders' Live Locations and Notes on a map",
		},
		video_url: MAP_DASHBOARD,
		articles: [
			{
				icon: 37,
				title: 'Request Layer',
				description:
					'Show requests on a map to visualize where requests have been made that require sending Responders.',
			},
			{
				icon: 62,
				title: "Responders' Live Location Layer",
				description:
					'Simultaneously, view the live locations and availability of Responders. Decide which Responders to attend to each Request based on their locations.',
			},
			{
				icon: 44,
				title: 'Sensors Layer',
				description: 'View the latest status of Internet of Things Sensors on the map.',
			},
			{
				icon: 15,
				title: 'Notes Layer',
				description: 'Record ad-hoc notes on the map.',
			},
		],
		plan: 'The Map Dashboard Feature is available with Professional and Plus plans',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-archive'} />,
			name: 'Vendor Management',
			group: 2,
		},
		slug: 'vendor-management',
		heading: {
			title: 'Vendor Management',
			description:
				'Manage all third-party vendor interactions in one system, including procurement, work allocation and invoice processing',
		},
		video_url: VENDOR_MANAGEMENT,
		articles: [
			{
				icon: 9,
				title: 'Manage Vendor List and Documents',
				description:
					'Maintain a list of vendors that you regularly interact with. Attach documents such as contracts or agreements as part of the vendor listing. From the vendor listing, use the Procurement feature to send Request for Quotations.',
			},
			{
				icon: 49,
				title: 'Receive and Process Invoices from Vendors',
				description:
					'Managing numerous invoices from third-party vendors can be a hassle. FacilityBot allows vendors to submit invoices and Facility Managers to process these invoices directly within the portal.',
			},
		],
		plan: 'The Vendor Management Feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-receipt'} />,
			name: 'Billing',
			group: 2,
		},
		slug: 'billing',
		heading: {
			title: 'Billing',
			description: "Use FacilityBot's Billing feature to send invoices to your customers",
		},
		video_url: BILLING,
		articles: [
			{
				icon: 29,
				title: 'Manage Customer List',
				description:
					'Facilities managers can maintain a list of cusotmers, such as building owners or tenants that they would like to bill. Customers can be linked to Requestors.',
			},
			{
				icon: 49,
				title: 'Send Bills via Email',
				description:
					'Use FacilityBot to send the billing email to the customer. FacilityBot will automatically track when and to whom the email was sent.',
			},
			{
				icon: 10,
				title: 'Link Bills to Underlying Work Done',
				description:
					'Facilities Managers can link bills to underlying requests and checklists to account for the actual work done in relation to the bill. Bills can also be linked to expenditures and vendor invoices.',
			},
			{
				icon: 5,
				title: 'Track Status of Bills',
				description: 'Easily track the status of bills, whether they have been sent and if they have been paid.',
			},
		],
		plan: 'The Billing Feature is available FREE with any subscription plan',
	},
	{
		submenu: {
			icon: <Licenses />,
			name: 'Licenses',
			group: 1,
		},
		slug: 'licenses',
		heading: {
			title: 'Licenses',
			description: 'Ensure regulatory compliance by tracking licensing in your facility',
		},
		video_url: LICENSES,
		articles: [
			{
				icon: 29,
				title: 'Easily create new Licenses',
				description:
					"FacilityBot's Licenses feature allows you to easily create Licenses that you wish to track. Attach PDF documents and link the licenses to assets and locations to provide more details regarding the licenses.",
			},
			{
				icon: 49,
				title: 'Get reminders of License renewals and track their renewal status',
				description:
					'In larger Facilities Management operations, compliance requirements can get tedious. FacilityBot allows you to easily set reminder emails when License expiry dates are approaching and track the renewal status of these Licenses.',
			},
		],
		plan: 'The Licenses feature is available with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-file-signature'} />,
			name: 'Contracts',
			group: 1,
		},
		slug: 'contracts',
		heading: {
			title: 'Contracts',
			description: 'Easily track Contracts that you have as part of your Facilities Management operations',
		},
		video_url: CONTRACTS,
		articles: [
			{
				icon: 29,
				title: 'Easily create new Contracts',
				description:
					"FacilityBot's Contracts feature allows you to easily create Contracts that you wish to track. Attach PDF documents and link the contracts to assets, locations, vendors, and customers to provide a richer context of the contracts.",
			},
			{
				icon: 49,
				title: 'Get reminders of Contract renewals and track their renewal status',
				description:
					'In larger Facilities Management operations, contract renewals may be hard to track. FacilityBot allows you to easily set reminder emails when Contract expiry dates are approaching and track the renewal status of these Contracts.',
			},
		],
		plan: 'The Contracts feature is available with any subscription plan',
	},
	{
		submenu: {
			icon: <QrCodeFaultReporting />,
			name: 'QR Code Fault Reporting',
			group: 1,
		},
		slug: 'qr_code_fault_reporting',
		heading: {
			title: 'QR Code Fault Reporting',
			description: 'Create unique QR codes through which Requestors can report faults and make service requests',
		},
		video_url: QR_CODE_FAULT_REPORTING,
		articles: [
			{
				icon: 56,
				title: 'Create unique QR Codes by selecting the form and location or asset',
				description:
					'FacilityBot allows the creation of unique QR codes for each location or asset. Therefore, when the Requestor scans the QR code, the location tag or asset ID would be pre-populated. Admins can also select the form that should appear when the QR code is scanned. The form could be a fault report form, any custom service request form, or a standardized form such as the toilet feedback form.',
			},
			{
				icon: 49,
				title: 'Scan and Report',
				description: `With FacilityBot's QR code web forms feature, making fault reports or service requests could not be easier for Requestors. Requestors simply scan the QR code, fill up the web form and submit. If the Requestor email is requested in the web form, FacilityBot can also automatically update Requestors of the request status via email.`,
			},
		],
		plan: 'The QR Code Web Forms feature is available in all plans ',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-calculator'} />,
			name: 'Budget',
			group: 2,
		},
		slug: 'budget',
		heading: {
			title: 'Budget',
			description: 'Set budgets to keep maintenance expenditures in check',
		},
		video_url: BUDGET,
		articles: [
			{
				icon: 32,
				title: 'Easily Create New Budgets',
				description:
					"FacilityBot's Budget feature allows admins to easily configure budgets for each cost center. Monthly, annual, and no-period budget thresholds can be easily set.",
			},
			{
				icon: 40,
				title: 'Easily View Budget Balances',
				description:
					'Once budgets are set, FacilityBot will automatically show the current balance for each budget based on the expenditures requested and approval for the specified cost center.',
			},
		],
		plan: 'The Budget feature is available with any subscription plan',
	},
	{
		submenu: {
			icon: <FontAwesomeIcon name={'fas fa-users'} />,
			name: 'Customers',
			group: 2,
		},
		slug: 'customers',
		heading: {
			title: 'Customers',
			description:
				'Manage Customers for Billing, Scheduling Tasks and Tracking Customer History. Great for Facilities and Field Service Managers with Multiple Customers.',
		},
		video_url: CUSTOMERS,
		articles: [
			{
				icon: 58,
				title: 'Manage Customer List',
				description:
					'Facilities and field service managers can maintain a list of customers, such as building owners or tenants that they would like to bill or schedule tasks for. Customers can be linked to Requestors.',
			},
			{
				icon: 59,
				title: 'View Customer History',
				description:
					'View Requests and Checklists that have been linked to this Customer. View all Billings for each Customer.',
			},
		],
		plan: 'The Customers Feature is available FREE with any subscription plan ',
	},
	{
		submenu: {
			icon: <Payments />,
			name: 'Payments',
			group: 2,
		},
		slug: 'payments',
		heading: {
			title: 'Payments',
			description: 'Collect Payments from Requestors / Tenants via Credit Card or PayNow.',
		},
		video_url: PAYMENTS,
		articles: [
			{
				icon: 60,
				title: 'Link to Stripe Payments Gateway',
				description:
					'FacilityBot is integrated to Stripe. Simply create a Stripe account and set it up with your bank account details, then link that account to FacilityBot. You will then be able to collect payments from your requestors or tenants directly via credit card or PayNow (for Singapore). Please note there will be <a href="https://stripe.com/en-sg/pricing" target="_blank" rel="noreferrer">fees imposed by Stripe</a>.',
			},
			{
				icon: 61,
				title: 'Collect Payments for Service Requests',
				description:
					"Once linked to Stripe, you can collect payments from Requestors / Tenants using FacilityBot's <a href='https://facilitybot.co/features/custom-service-requests' target='_blank' rel='noreferrer'>Service Request feature</a> by configuring service request forms to include Payments. Facility Managers can then collect Payments for services such as carpark fees, extending air conditioning hours and events set up",
			},
		],
		plan: 'The Payments feature is available with the Professional and Plus plans',
	},
	{
		slug: 'virtual_demo',
		heading: {
			title: 'FacilityBot Virtual Demo',
			description: 'If you have any questions during the virtual demo, please use the chat widget on the bottom right',
		},
		video_url: VIRTUAL_DEMO,
	},
	{
		slug: 'field_service_management',
		heading: {
			title: 'Field Services Management',
			description: 'FacilityBot has been designed to seamlessly support field services teams',
		},
		articles: [
			{
				icon: 58,
				title: 'Customers',
				description:
					'Maintain your list of customers and their addresses. Include their email address and service reports will be automatically emailed to them.',
			},
			{
				icon: 5,
				title: 'Checklists and Service Reports',
				description:
					'Configure checklists to be completed by your technicians. Technicians can complete these checklists on the move using the FacilityBot Mobile App.',
			},
			{
				icon: 8,
				title: 'Scheduling',
				description:
					'Dispatchers use our intuitive drag-and-drop interface to schedule tasks for field teams. Field Teams receive notifications on their mobile app and can access the relevant checklists with one click.',
			},
			{
				icon: 16,
				title: 'Check-Ins and Live Location',
				description:
					'Know where all field teams are by viewing their live locations on a map. Field teams can also check-in to sites as an attendance record.',
			},
		],
		video_url: FIELD_SERVICE_MANAGEMENT,
		plan: 'Find out how FacilityBot can help you easily digitize and automate your field service operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
];

const TEMP_FEATURES = [...FEATURES];
TEMP_FEATURES.splice(11, 0, {
	submenu: {
		icon: <Hotdesks />,
		name: 'Hotdesks',
		group: 3,
	},
	slug: 'hotdesks',
});

export const FEATURES_SUBMENU = TEMP_FEATURES.map(({ slug, submenu }) => {
	if (!submenu) return;
	return {
		link: `/features/${slug}`,
		icon: submenu.icon,
		name: submenu.name,
		group: submenu.group,
	};
});
