import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 36"}</title>
      <g transform="translate(-427 -1548)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={1548} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M462 1582h-11a1 1 0 01-1-.9l-.2-2.1H448a1 1 0 01-.961-1.274l2-7A1 1 0 01450 1570h11a1 1 0 011 .9l1 10a1 1 0 01-1 1.1zm-10.095-2h8.995l-.8-8h-8.995l.8 8z" />
          <path d="M454 1574h4v1h-4zM454 1577h4v1h-4z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
