import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 21"}</title>
      <g transform="translate(-427 -612)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={612} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M448 648a1 1 0 01-.707-1.707l5-5a1 1 0 011.414 1.414l-5 5A1 1 0 01448 648zM447.966 642.792l1.522-1.523-.055-.128c-.006-.016-.576-1.584.275-2.435l4.03-4.03c.842-.842 2.4-.287 2.4-.288l.163.07 1.522-1.523-.933-.4c-.113-.044-2.79-1.046-4.567.728l-4.03 4.03c-1.773 1.771-.77 4.453-.712 4.601l.385.898zM452.208 647.034l1.523-1.522.128.055c.016.006 1.584.576 2.435-.275l4.03-4.03c.842-.842.287-2.4.288-2.4l-.07-.163 1.523-1.523.4.933c.044.113 1.046 2.794-.728 4.567l-4.03 4.03c-1.771 1.773-4.453.77-4.6.712l-.899-.384zM457 639a1 1 0 01-.707-1.707l5-5a1 1 0 011.414 1.414l-5 5A1 1 0 01457 639z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
