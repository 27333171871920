import Link from 'next/link';

import { useToggleMenu } from '../../hooks';
import { BUILDING_TYPES, SOLUTION_GROUP } from '../../data/building_types';
import { SubmenuDropdownItem, calculateColumns } from './NavFeatItem';
import { splitArrToChunks } from '../../utils';

const SubmenuDropdownColumn = ({ data, columns }) => (
	<div className="dropdown__column-wrapper">
		{splitArrToChunks(data, columns).map((chunk, columnIndex) => {
			return (
				<ul key={columnIndex} className="dropdown__column">
					{chunk.map((item, itemIndex) => {
						const mapItem = {
							link: `/building-types/${item.slug}`,
							icon: item.submenu.icon,
							name: item.submenu.name,
						};
						return <SubmenuDropdownItem key={itemIndex} {...mapItem} />;
					})}
				</ul>
			);
		})}
	</div>
);

export default function NavSolutionItem({ className, displayText }) {
	const [toggle, setToggle] = useToggleMenu();

	return (
		<li className="header__nav-item header__nav-item--dropdown" onClick={setToggle}>
			<span>{displayText}</span>

			<ul className={`submenu ${className} ${toggle ? 'is-mobile' : ''}`}>
				<div className="dropdown__section-list">
					{SOLUTION_GROUP.map((group, groupIndex) => {
						const submenu = BUILDING_TYPES.filter((item) => item?.group === groupIndex + 1);
						return (
							<div className="dropdown__section" key={groupIndex}>
								<Link href={group.link} legacyBehavior>
									<h3
										className="dropdown__column-label"
										style={{
											color: group.color,
										}}
									>
										{group.name}
									</h3>
								</Link>

								<SubmenuDropdownColumn data={submenu} columns={calculateColumns(submenu.length)} />
							</div>
						);
					})}
				</div>
			</ul>
		</li>
	);
}
