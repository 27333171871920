import Link from 'next/link';
import { FacilityBotLogo } from '../Svg';

export default function HeaderLogo() {
	return (
		<div className="header__logo">
			<Link href="/">
				<FacilityBotLogo />
			</Link>
		</div>
	);
}
