import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 32"}</title>
      <g transform="translate(-101 -1314)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={1314} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M133.7 1334.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.8.8-.9.9c-1.2-.9-2.6-1.4-4.2-1.4-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7c0-1.6-.5-3-1.4-4.2l.9-.9.8.8c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3zM128 1348c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" />
          <path d="M129 1340h-2v4h4v-2h-2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
