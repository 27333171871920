import * as React from "react"

function SvgCheckboxFeature(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <title>{"check-e copy 4"}</title>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M8.8 0C3.948 0 0 3.948 0 8.8s3.948 8.8 8.8 8.8 8.8-3.948 8.8-8.8S13.652 0 8.8 0zm0 15.4c-3.64 0-6.6-2.96-6.6-6.6 0-3.64 2.96-6.6 6.6-6.6 3.64 0 6.6 2.96 6.6 6.6 0 3.64-2.96 6.6-6.6 6.6z" />
        <path d="M7.7 12.555L3.945 8.8 5.5 7.245l2.2 2.2 4.4-4.4L13.655 6.6z" />
      </g>
    </svg>
  )
}

export default SvgCheckboxFeature;
