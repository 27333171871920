import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 38"}</title>
      <g transform="translate(-741 -1548)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={1548} width={55} height={55} rx={10} />
        <path
          d="M776.9 1581.6l-1.9-4.8v-6.8c0-.6-.4-1-1-1h-10c-.6 0-1 .4-1 1v6.8l-1.9 4.8c-.2.3-.2 1.4.9 1.4h14c1.1 0 1.1-1.1.9-1.4zM773 1571v5h-8v-5h8z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
