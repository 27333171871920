import Link from 'next/link';
import { splitArrToChunks } from '../../utils';

const SubmenuItem = ({ link, icon, name, additional_submenu }) => {
	const hasDropdownClass = additional_submenu ? 'submenu__item--dropdown' : '';

	return (
		<li className={`submenu__item ${hasDropdownClass}`}>
			<Link href={link} className="submenu__link">
				{icon}
				<span>{name}</span>
			</Link>

			{additional_submenu && (
				<ul className="submenu-dropdown__list">
					{additional_submenu.map((item, itemIndex) => (
						<li className="submenu-dropdown__item" key={itemIndex}>
							<Link href={`/resources/versus/${item.slug}`} className="submenu-dropdown__link">
								{item.name}
							</Link>
						</li>
					))}
				</ul>
			)}
		</li>
	);
};

export default function NavSubmenu({ data, columns }) {
	return (
		<>
			{splitArrToChunks(data, columns).map((chunk, columnIndex) => {
				return (
					<div key={columnIndex}>
						{chunk.map((item, itemIndex) => (
							<SubmenuItem key={itemIndex} {...item} />
						))}
					</div>
				);
			})}
		</>
	);
}
