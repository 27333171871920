import * as React from "react"

export function AddIcon(props) {
  return (
    <svg width={16} height={4} viewBox="0 0 16 4" {...props}>
      <path d="M0 0v4h16V0H0z" fill="#f7654b" />
    </svg>
  )
}

export function MinusIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path d="M10 0H6v6H0v4h6v6h4v-6h6V6h-6z" fill="#f7654b" />
    </svg>
  )
}
