import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <path
        d="M35 24l-4 4h3c0 3.31-2.69 6-6 6a5.87 5.87 0 01-2.8-.7l-1.46 1.46A7.93 7.93 0 0028 36c4.42 0 8-3.58 8-8h3l-4-4zm-13 4c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46A7.93 7.93 0 0028 20c-4.42 0-8 3.58-8 8h-3l4 4 4-4h-3z"
        fill="#FF6B4E"
      />
    </svg>
  )
}

export default SvgComponent
