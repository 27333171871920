import * as React from 'react';

function SvgTenantPortal() {
	return (
		<svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
			<g fill="#F7654B" fillRule="nonzero">
				<path d="M 5.5 0.867188 C 5.382812 0.734375 5.210938 0.660156 5.035156 0.667969 C 4.859375 0.667969 4.691406 0.742188 4.566406 0.867188 C 4.4375 0.988281 4.363281 1.15625 4.363281 1.332031 C 4.363281 1.511719 4.4375 1.679688 4.566406 1.800781 L 6.464844 3.667969 L 2.066406 8.035156 C 1.8125 8.277344 1.671875 8.613281 1.671875 8.964844 C 1.671875 9.320312 1.8125 9.65625 2.066406 9.898438 L 6.464844 14.265625 C 6.707031 14.523438 7.046875 14.667969 7.398438 14.667969 C 7.753906 14.664062 8.089844 14.519531 8.332031 14.265625 L 13.667969 8.964844 Z M 3.035156 8.964844 L 7.398438 4.632812 L 11.734375 8.964844 Z M 3.035156 8.964844 "></path>
				<path d="M 13.667969 10.667969 C 13.667969 10.667969 12.332031 12.601562 12.332031 13.332031 C 12.332031 14.070312 12.929688 14.667969 13.667969 14.667969 C 14.402344 14.667969 15 14.070312 15 13.332031 C 15 12.601562 13.667969 10.667969 13.667969 10.667969 Z M 13.667969 10.667969 "></path>
			</g>
		</svg>
	);
}

export default SvgTenantPortal;
