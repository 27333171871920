import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 29"}</title>
      <g transform="translate(-427 -1080)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={1080} width={55} height={55} rx={10} />
        <path
          d="M449.6 1109.6v4.9c0 .7.8 1 1.3.5l2.3-2.7 4.7 3.5c.4.3 1 .1 1.1-.4l3-14.5c.1-.6-.4-1-1-.8l-14.5 5.8c-.5.2-.6.9-.2 1.3l1.6 1.2 4.6-2.2c.4-.2.7.3.4.5l-3.3 2.9z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
