import ChannelsList from './ChannelsList';
import { illustrations_1 } from '../utils/images';

export default function Demo() {
	return (
		<section className="demo">
			<div className="container">
				<div className="demo__wrapper">
					<div className="demo__heading">
						<div className="demo__content">
							<h2 className="demo__content-title">Use whichever messaging platform you prefer</h2>
							<h3 className="demo__content-desc">no downloads. no logins</h3>
						</div>

						<ChannelsList />
					</div>

					<img src={illustrations_1} alt="" className="demo__illustration" />
				</div>
			</div>
		</section>
	);
}
