import { useState } from 'react';
import fcbService from '../services/fcbService';
import { objectToFormData, convertArray, payloadToCreateChecklist } from '../utils/pdf';
import { setCookie, domainName } from '../utils';

import * as dayjs from 'dayjs';
import mailjetService, { interestedLeadsListId } from '../services/mailjetService';
import senderService from '../services/senderService';
import { SENDER_GROUPS } from '../constants/sender';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRegisterForm(type, checklistData) {
	let initialInputs = {
		manager_name: '',
		agent_name: '',
		email: '',
		password: '',
	};

	const [inputs, setInputs] = useState(initialInputs);
	const [msg, setMsg] = useState('');
	const [referred_code, setReferredCode] = useState('');
	const [loading, setLoading] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleInputChange = (e) => {
		e.persist();
		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async (e) => {
		setLoading(true);

		try {
			e.preventDefault();

			if (!executeRecaptcha) {
				return;
			}

			const captcha_response = await executeRecaptcha('register_form');

			// will drop the user information into Interested Leads contact list in mailjet
			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Name: inputs.manager_name,
				Properties: {
					name: inputs.manager_name,
					company: inputs.agent_name,
				},
				Action: 'addnoforce',
				Email: inputs.email,
			});

			await senderService.post('/subscribers', {
				email: inputs.email,
				firstname: inputs.manager_name,
				groups: [SENDER_GROUPS.TRIAL_SIGN_UPS],
			});

			const res = await fcbService.post('/subscriptions', {
				...inputs,
				referred_code,
				captcha_response,
			});

			const { access_token } = res.data;

			setCookie('tRefresh', `${access_token}`, 15, domainName());

			setMsg('Trial Created Successfully');
			setLoading(false);

			if (type.name === 'pdf') {
				const { description, list, title } = checklistData;
				const rowsPayload = convertArray(list);
				const formattedTime = dayjs().format('DD/MM/YYYY HH:mm');
				const futureDate = dayjs().add(1, 'year').format('DD/MM/YYYY');
				const payload = payloadToCreateChecklist(title, description, formattedTime, futureDate, rowsPayload);
				const checklistFormData = objectToFormData(payload);

				const responseToken = await fcbService.get('managers/authentication_token', {
					params: {
						access_token: access_token,
					},
				});

				const authenticateToken = responseToken?.data.authenticate_token;

				const res = await fcbService.post('checklist_settings', checklistFormData, {
					headers: {
						'X-Authentication-Token': authenticateToken,
					},
				});

				const checklistId = await res?.data?.id;
				let redirectLink = `${process.env.NEXT_PUBLIC_FCB_APP_URL}/?access_token=${access_token}&redirect_to=/settings/checklist_settings/${checklistId}?tab=draft`;
				window.open(redirectLink, '_blank');
			} else {
				//TODO: combine url into only 1 only
				let chatbotUrl = `${process.env.NEXT_PUBLIC_FCB_APP_URL}/?access_token=`;
				let redirectLink;

				switch (type.name) {
					case 'trial':
						redirectLink = chatbotUrl + access_token + '&redirect_to=/';
						break;
					case 'subscription':
						redirectLink = chatbotUrl + access_token + '&redirect_to=/subscription_plans';
						break;
					case 'checklist':
						redirectLink = chatbotUrl + access_token + '&redirect_to=/settings/checklist_settings';
						break;
				}

				window.location.assign(redirectLink);
			}
		} catch (err) {
			console.log(err);
			const { message } = err.response.data;
			setMsg(message);
		}
	};

	const afterClose = () => {
		setMsg('');
		setLoading(false);
		setInputs({ ...initialInputs });
	};

	return {
		inputs,
		msg,
		handleInputChange,
		handleSubmit,
		afterClose,
		setReferredCode,
		loading,
	};
}
