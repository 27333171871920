import { useState, useCallback, useEffect } from 'react';
import copy from 'copy-to-clipboard';

export default function useCopyToClipboard(resetInterval = null) {
	const [isCopied, setCopied] = useState(false);

	const handleCopy = useCallback((text) => {
		copy(text);
		setCopied(true);
	}, []);

	useEffect(() => {
		let timeout;
		if (isCopied && resetInterval) {
			timeout = setTimeout(() => setCopied(false), resetInterval);
		}

		return () => {
			clearInterval(timeout);
		};
	}, [isCopied, resetInterval]);

	return [isCopied, handleCopy];
}
