import { useToggleMenu } from '../../hooks';
import NavSubmenu from './NavSubmenu';

export default function NavItem({ className, displayText, submenu }) {
	const [toggle, setToggle] = useToggleMenu();

	return (
		<li className="header__nav-item header__nav-item--dropdown" onClick={setToggle}>
			<span>{displayText}</span>

			<ul className={`submenu ${className} ${toggle ? 'is-mobile' : ''}`}>
				<NavSubmenu data={submenu.data} columns={submenu.columns} />
			</ul>
		</li>
	);
}
