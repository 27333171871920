import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 56"}</title>
      <g transform="translate(-101 -2734)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={2734} width={55} height={55} rx={10} />
        <path
          d="M135 2754h-12c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-14c0-.6-.4-1-1-1zm-10 3h8v7h-3v3h-5v-10z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
