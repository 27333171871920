import * as React from "react";

function SvgQrCodeScanning(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M6 7H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1zM2 5h3V2H2v3zM6 16H1c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1zm-4-2h3v-3H2v3zM15 7h-5c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1zm-4-2h3V2h-3v3z" />
        <path d="M3 3h1v1H3zM3 12h1v1H3zM9 14h7v2H9zM16 13h-2v-2h-3V9h4c.6 0 1 .4 1 1v3z" />
        <path d="M12 12h1v1h-1zM12 3h1v1h-1z" />
      </g>
    </svg>
  );
}

export default SvgQrCodeScanning;
