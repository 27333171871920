import { TwitterIcon, FacebookIcon, LinkedinIcon, YoutubeIcon } from '../Svg'
import { FACEBOOK_LINK, TWITTER_LINK, YOUTUBE_LINK, LINKEDIN_LINK } from '../../constants'

export default function Footer() {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer__wrapper'>
					<div className='footer__legal-copy'>
						<span>© 2020 FacilityBot Pte Ltd</span>
					</div>

					<div className='footer__social-icons'>
						<span>
							<a href={FACEBOOK_LINK} target='_blank' rel='noreferrer'>
								<FacebookIcon />
							</a>
						</span>
						<span>
							<a href={LINKEDIN_LINK} target='_blank' rel='noreferrer'>
								<LinkedinIcon />
							</a>
						</span>
						<span>
							<a href={TWITTER_LINK} target='_blank' rel='noreferrer'>
								<TwitterIcon />
							</a>
						</span>
						<span>
							<a href={YOUTUBE_LINK} target='_blank' rel='noreferrer'>
								<YoutubeIcon />
							</a>
						</span>
					</div>
				</div>
			</div>
		</footer>
	);
}
