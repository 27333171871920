import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 30"}</title>
      <g transform="translate(-1069 -1080)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={1080} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1101 1100h-8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2v-12a2 2 0 00-2-2zm-8 13v-10h8v10h-8z" />
          <path d="M1097.986 1104l-3.986 5h2.5l-.5 3 4-5h-2.5z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
