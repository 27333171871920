import React, { useContext } from 'react';
import { NonceContext } from '../../pages/_app';

export default function LinkedinWidget() {
	const nonce = useContext(NonceContext);

	return (
		<div className="widget--linkedin">
			<div className="sk-ww-linkedin-page-post" data-embed-id="199608">
				<script src="https://widgets.sociablekit.com/linkedin-page-posts/widget.js" async defer nonce={nonce}></script>
			</div>
		</div>
	);
}
