import * as React from "react";

function SvgKnowledgeBase(props) {
  return (
    <svg width={16} height={14} {...props}>
      <path
        d="M11.5 0C10.1 0 8.8.4 8 1.1 7.2.4 5.9 0 4.5 0 1.9 0 0 1.3 0 3v10c0 .6.4 1 1 1s1-.4 1-1c0-.3.9-1 2.5-1s2.5.7 2.5 1c0 .6.4 1 1 1s1-.4 1-1c0-.3.9-1 2.5-1s2.5.7 2.5 1c0 .6.4 1 1 1s1-.4 1-1V3c0-1.7-1.9-3-4.5-3zm-7 10c-.9 0-1.8.2-2.5.5V3c0-.3.9-1 2.5-1S7 2.7 7 3v7.5c-.7-.3-1.6-.5-2.5-.5zm9.5.5c-.7-.3-1.6-.5-2.5-.5-.9 0-1.8.2-2.5.5V3c0-.3.9-1 2.5-1s2.5.7 2.5 1v7.5z"
        fill="#F7654B"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgKnowledgeBase;
