import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#F7654B"
        d="M0 12h4v4H0zM3 9h4v2h2V9h4v2h2V8c0-.6-.4-1-1-1H9V5H7v2H2c-.6 0-1 .4-1 1v3h2V9z"
      />
      <path
        d="M16 11h-2c0-3.309-2.691-6-6-6V3c4.411 0 8 3.589 8 8z"
        fill="#F7654B"
      />
      <path
        d="M12 11h-2c0-1.103-.897-2-2-2V7c2.206 0 4 1.794 4 4zM6 12h4v4H6zM6 0h4v4H6zM12 12h4v4h-4z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
