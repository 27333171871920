import AccordionMenu from './AccordionMenu';
import { FAQS } from '../data/FAQ';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function Faq() {
	return (
		<section className="faq">
			<div className="sm-container">
				<div className="faq-wrapper">
					<h2 className="faq__heading">frequently asked questions</h2>

					<AccordionMenu>
						{FAQS.map((value, index) => (
							<Panel header={value.question} key={index + 1} index={index}>
								{index == 0 || index == 1 || FAQS.length - 1 ? (
									<div className="answer-content">
										<p className="answer-content--body" dangerouslySetInnerHTML={{ __html: value.answer }} />
									</div>
								) : (
									<div className="answer-content">
										<p className="answer-content--body">{value.answer}</p>
									</div>
								)}
							</Panel>
						))}
					</AccordionMenu>
				</div>
			</div>
		</section>
	);
}
