import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 4H4v2h2V4zM6 8H4v2h2V8zM10 4H8v2h2V4zM10 8H8v2h2V8z"
        fill="#F7654B"
      />
      <path
        d="M13.165 1.014l-6-1a1.015 1.015 0 00-.33 0l-6 1A1 1 0 000 2v13a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-.835-.986zM12 13.998H8v-2H6v2H2V2.848l5-.833 5 .833v11.152z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
