import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 40"}</title>
      <g transform="translate(-427 -1782)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={1782} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M453.5 1802c-3.59 0-6.5 2.462-6.5 5.5 0 1.075.37 2.074 1 2.922V1814l2.699-1.542a7.454 7.454 0 002.801.542c3.59 0 6.5-2.462 6.5-5.5s-2.91-5.5-6.5-5.5z" />
          <path d="M463 1811.5c0-.987-.429-1.897-1.147-2.639-.729 3.487-4.193 6.139-8.353 6.139-.103 0-.202-.018-.305-.021 1.036.638 2.361 1.021 3.805 1.021.449 0 .886-.04 1.307-.11L462 1818v-4h-.012c.639-.715 1.012-1.575 1.012-2.5z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
