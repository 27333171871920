import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <path
        d="M34.4 20H21.6c-.888 0-1.6.712-1.6 1.6v8.8c0 .888.712 1.6 1.6 1.6h3.2v4l3.2-1.6 3.2 1.6v-4h3.2c.888 0 1.6-.712 1.6-1.6v-8.8c0-.888-.712-1.6-1.6-1.6zm0 10.4H21.6v-1.6h12.8v1.6zm0-4H21.6v-4.8h12.8v4.8z"
        fill="#FF6B4E"
      />
    </svg>
  )
}

export default SvgComponent
