import * as React from 'react';

function SvgQrCodeFaultReporting(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none" viewBox="0 0 16 16" {...props}>
			<path
				stroke="#F7654B"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				fill="#F7654B"
				fillRule="nonzero"
				d="M1.5 1h3v3h-3V1zM11.5 1h3v3h-3V1zM1.5 11h3v3h-3v-3zM11.5 11v3h3v-3h-1M6.5 3.5V1h3M9.5 3v3.5M1.5 9h5V6.5M6.5 11v3h3M8.5 11h1M1.5 6h3M14.5 6h-3v1M8.5 9h6"
			></path>
		</svg>
	);
}

export default SvgQrCodeFaultReporting;
