import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 54"}</title>
      <g transform="translate(-1069 -2483)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={2483} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1090 2518h13a1 1 0 000-2h-13a1 1 0 000 2zM1095 2504h3v10h-3zM1090 2508h3v6h-3zM1100 2510h3v4h-3z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
