import { Modal } from 'antd';

export default function Popup({ visible, onCancel, children, afterClose, modalWidth = '1200px' }) {
	return (
		<Modal
			centered
			open={visible}
			footer={null}
			onCancel={onCancel}
			afterClose={afterClose}
			wrapClassName="popup-wrapper"
			width={modalWidth}
		>
			{children}
		</Modal>
	);
}
