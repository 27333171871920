import AccordionMenu from '../AccordionMenu';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function VsCompetitorFaq() {
	const FAQS = [
		{
			question: 'How does FacilityBot differ from its competitors?',
			answer: `
        <p>FacilityBot is a unique omnichannel, feature-rich, and integration-friendly CMMS system that acts as the backbone for Smart Facilities Management deployment</p>
        <p>Building occupants can use their preferred messaging channel (including Whatsapp, Telegram, Messenger, Line, Google Chat, Slack, Teams, Line, and WebChat), QR codes to web forms, as well as direct email to the system, increasing adoption and improving user experience</p>
      `,
		},
		{
			question: 'How do I choose the right Facilities Management Software (CMMS) for my organization?',
			answer: `
        <p>Facility management software comes with many features. Before you begin looking one, determine your facilities management needs. Computer-aided facilities management software or CMMS works best when you understand how and to what degree you'll use it</p>
        <p>User experience is often overlooked in CMMS systems, resulting in poor utilization. A smart facility management system cannot be effectively deployed if the CMMS is not properly utilized, and manual processes are still in place</p>
      `,
		},
	];

	return (
		<section className="faq faq--bg-white">
			<div className="sm-container">
				<div className="faq-wrapper">
					<h2 className="faq__heading">frequently asked questions</h2>

					<AccordionMenu>
						{FAQS.map((value, index) => (
							<Panel header={value.question} key={index + 1} index={index}>
								<div className="answer-content">
									<p className="answer-content--body" dangerouslySetInnerHTML={{ __html: value.answer }} />
								</div>
							</Panel>
						))}
					</AccordionMenu>
				</div>
			</div>
		</section>
	);
}
