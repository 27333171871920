import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 11"}</title>
      <g transform="translate(-741 -378)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={378} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M776 407l-5-4v3h-5v2h5v3z" />
          <path d="M775 400h-2v-2h-2v2h-4v-2h-2v2h-2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1v-2h-2v1h-10v-10h10v1h2v-2a1 1 0 00-1-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
