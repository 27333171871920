import Link from 'next/link';

export default function NavLinkItem({ href, displayText, onClick }) {
	return (
		<li className="header__nav-item" onClick={onClick}>
			<Link href={href} className="header__nav-link">
				{displayText}
			</Link>
		</li>
	);
}
