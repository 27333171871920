import * as React from "react";

function SvgRoiCalculator(props) {
  return (
    <svg width={16} height={14} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M5 2H4V1c0-.6-.4-1-1-1S2 .4 2 1v1H1c-.6 0-1 .4-1 1s.4 1 1 1h1v1c0 .6.4 1 1 1s1-.4 1-1V4h1c.6 0 1-.4 1-1s-.4-1-1-1zM5.7 8.3c-.4-.4-1-.4-1.4 0L3 9.6 1.7 8.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L1.6 11 .3 12.3c-.4.4-.4 1 0 1.4.2.2.9.5 1.4 0L3 12.4l1.3 1.3c.5.5 1.2.2 1.4 0 .4-.4.4-1 0-1.4L4.4 11l1.3-1.3c.4-.4.4-1 0-1.4zM10 4h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1s.4 1 1 1zM15 8h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1zM15 12h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1z" />
      </g>
    </svg>
  );
}

export default SvgRoiCalculator;
