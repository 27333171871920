import icon from '../public/images/facilitybot_favicon.png';
import customers from '../public/images/customers.png';
import partners from '../public/images/partners.png';
import bgCta from '../public/images/bg-cta.png';
import bannerFacilityBot from '../public/images/banner.png';

//demo logo
import whatsapp from '../public/images/demo/whatsapp.png';
import telegram from '../public/images/demo/telegram.png';
import messenger from '../public/images/demo/messenger.png';
import workchat from '../public/images/demo/workchat.png';
import widget from '../public/images/demo/widget.png';
import ios from '../public/images/demo/ios.png';
import android from '../public/images/demo/android.png';
import line from '../public/images/demo/line.png';
import googleChat from '../public/images/demo/googleChat.png';
import slack from '../public/images/demo/slack.png';
import teams from '../public/images/demo/teams.png';
import email from '../public/images/demo/email.png';
import viber from '../public/images/demo/viber.png';

//platform logo
import whatsappPlatform from '../public/images/platform/whatsapp.png';
import azurePlatform from '../public/images/platform/azure.png';
import linePlatform from '../public/images/platform/line.png';
import messengerPlatform from '../public/images/platform/messenger.png';
import metabasePlatform from '../public/images/platform/metabase.png';
import restapiPlatform from '../public/images/platform/restapi.png';
import seleniumPlatform from '../public/images/platform/selenium.png';
import telegramPlatform from '../public/images/platform/telegram.png';
import webhooksPlatform from '../public/images/platform/webhooks.png';
import workplacePlatform from '../public/images/platform/workplace.png';
import mqttPlatform from '../public/images/platform/mqtt.png';
import smartTechPlatform from '../public/images/platform/smart_tech.png';
import smartOccupancyPlatform from '../public/images/platform/smart_occupancy.png';
import googleChatPlatform from '../public/images/platform/googleChat.png';
import dormakabaPlatform from '../public/images/platform/dormakaba.png';
import skylabPlatform from '../public/images/platform/skylab.png';
import nodeRedPlatform from '../public/images/platform/node_red.png';
import swipeTaskPlatform from '../public/images/platform/swipetask.png';
import slackPlatform from '../public/images/platform/slack.png';
import teamsPlatform from '../public/images/platform/microsoft_teams.png';
import zapierPlatform from '../public/images/platform/zapier.png';
import monnitPlatform from '../public/images/platform/monnit.png';
import milesightPlatform from '../public/images/platform/milesight.png';
import sensmaxPlatform from '../public/images/platform/sensmax.png';
import evercommPlatform from '../public/images/platform/evercomm.png';
import viberPlatform from '../public/images/platform/viber.png';
import bacnetPlatform from '../public/images/platform/bacnet.png';
import jtcPlatform from '../public/images/platform/jurong_town.png';
import proCorePlatform from '../public/images/platform/procore.png';
import oauth2Platform from '../public/images/platform/oauth2.png';
import samlPlatform from '../public/images/platform/saml.png';
import loriotPlatform from '../public/images/platform/loriot.png';
import uHooPlatform from '../public/images/platform/uhoo.png';

//sme logo
import sme from '../public/images/sme/sme.png';

//illustration images
import illustrations_1 from '../public/images/illustrations/illustrations_1.png';
import illustrations_2 from '../public/images/illustrations/illustrations_2.png';
import illustrations_3 from '../public/images/illustrations/illustrations_3.png';
import illustrations_4 from '../public/images/illustrations/illustrations_4.png';

// security status images
import risk_profile from '../public/images/security-status/risk_profile.png';
import product_security from '../public/images/security-status/product_security.png';
import reports from '../public/images/security-status/reports.png';
import data_security from '../public/images/security-status/data_security.png';
import app_security from '../public/images/security-status/app_security.png';
import access_control from '../public/images/security-status/access_control.png';
import infrastructure from '../public/images/security-status/infrastructure.png';
import security_grades from '../public/images/security-status/security_grades.png';
import endpoint_security from '../public/images/security-status/endpoint_security.png';
import network_security from '../public/images/security-status/network_security.png';
import policies from '../public/images/security-status/policies.png';
import customer_data from '../public/images/security-status/customer_data.png';

// HotDesks gif
import place_desks from '../public/images/gif/place_desks.gif';
import set_permissions from '../public/images/gif/set_permissions.gif';
import book_desk from '../public/images/gif/book_desk.gif';

// Award
import greatUserExperienceAward from '../public/images/great-user-experience-award.png';
import risingStarAward from '../public/images/rising-star-2020-award.png';
import SIFMAAward from '../public/images/sifma-award.png';
import asiaPacificAward from '../public/images/asia-pacific-award.png';

// Country flags
import indiaFlag from '../public/images/india_flag.png';
import thailandFlag from '../public/images/thailand_flag.png';
import australiaFlag from '../public/images/australia_flag.png';
import malaysiaFlag from '../public/images/malaysia_flag.png';
import indonesiaFlag from '../public/images/indonesia_flag.png';
import southAfricaFlag from '../public/images/south_africa_flag.png';
import sriLankaFlag from '../public/images/sri_lanka_flag.png';
import brazilFlag from '../public/images/brazil_flag.png';
import dubaiFlag from '../public/images/dubai_flag.png';

import previewContentImage from '../public/images/preview_content.png';

// Slack gif
import createReportFault from '../public/images/gif/create_report_fault.gif';
import createServiceRequest from '../public/images/gif/create_service_request.gif';
import bookHotdesks from '../public/images/gif/book_desk.gif';
import bookFacilities from '../public/images/gif/book_facilities.gif';
import inviteVisitors from '../public/images/gif/invite_visitors.gif';
import slackGuide from '../public/images/slack-guide.png';

import pricingPlansThumbnail from '../public/images/pricing-plans-thumbnail.png';

// Versus Competitor
import userImg from '../public/images/user-img.png';
import messagingImg from '../public/images/messaging-img.png';
import integrationImg from '../public/images/integration-img.png';
import versusCompetitorHero from '../public/images/versus-competitor-hero.png';

import facilitybotLogo from '../public/images/facilitybot-logo.png';

export {
	icon,
	customers,
	partners,
	bgCta,
	sme,
	whatsapp,
	telegram,
	messenger,
	workchat,
	widget,
	ios,
	android,
	line,
	googleChat,
	slack,
	teams,
	email,
	viber,
	whatsappPlatform,
	azurePlatform,
	linePlatform,
	messengerPlatform,
	metabasePlatform,
	restapiPlatform,
	seleniumPlatform,
	telegramPlatform,
	webhooksPlatform,
	workplacePlatform,
	mqttPlatform,
	smartTechPlatform,
	smartOccupancyPlatform,
	googleChatPlatform,
	dormakabaPlatform,
	skylabPlatform,
	nodeRedPlatform,
	swipeTaskPlatform,
	slackPlatform,
	teamsPlatform,
	zapierPlatform,
	monnitPlatform,
	milesightPlatform,
	sensmaxPlatform,
	evercommPlatform,
	viberPlatform,
	bacnetPlatform,
	illustrations_1,
	illustrations_2,
	illustrations_3,
	illustrations_4,
	bannerFacilityBot,
	risk_profile,
	product_security,
	reports,
	data_security,
	app_security,
	access_control,
	infrastructure,
	security_grades,
	endpoint_security,
	network_security,
	policies,
	customer_data,
	place_desks,
	set_permissions,
	book_desk,
	greatUserExperienceAward,
	risingStarAward,
	SIFMAAward,
	asiaPacificAward,
	indiaFlag,
	thailandFlag,
	australiaFlag,
	malaysiaFlag,
	indonesiaFlag,
	southAfricaFlag,
	sriLankaFlag,
	previewContentImage,
	createReportFault,
	createServiceRequest,
	bookHotdesks,
	bookFacilities,
	inviteVisitors,
	slackGuide,
	pricingPlansThumbnail,
	userImg,
	messagingImg,
	integrationImg,
	versusCompetitorHero,
	facilitybotLogo,
	jtcPlatform,
	proCorePlatform,
	oauth2Platform,
	samlPlatform,
	loriotPlatform,
	uHooPlatform,
	brazilFlag,
	dubaiFlag,
};
