import { useState, createRef } from 'react';
import { useRegisterForm } from '../../hooks';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from '../../constants';
import Popup from '../Popup';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function SignupForm({ btnClass, btnText, type, checklistData }) {
	const [visible, setVisible] = useState(false);
	const { msg, inputs, handleInputChange, handleSubmit, afterClose, loading } = useRegisterForm(type, checklistData);

	return (
		<>
			<button className={btnClass} onClick={() => setVisible(!visible)}>
				{btnText}
			</button>
			<Popup visible={visible} onCancel={() => setVisible(!visible)} afterClose={afterClose}>
				<div className="sign-up">
					<div className="sign-up__title-wrapper">
						<h2 className="sign-up__title">
							Thanks for your interest to {type.name == 'trial' ? 'trial' : 'use'} <span>Facility</span>Bot
						</h2>
						<h3 className="sign-up__subtitle">We just need the following information to get started</h3>
					</div>

					<form className="sign-up__form" onSubmit={handleSubmit}>
						<div className="sign-up__form-row">
							<div className="sign-up__form-field">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									id="email"
									name="email"
									required
									autoFocus
									placeholder="Email Address"
									value={inputs.email}
									onChange={handleInputChange}
								/>
							</div>
							<div className="sign-up__form-field">
								<label htmlFor="manager_name">User Name</label>
								<input
									type="text"
									id="manager_name"
									name="manager_name"
									required
									placeholder="Name"
									value={inputs.manager_name}
									onChange={handleInputChange}
								/>
							</div>
						</div>

						<div className="sign-up__form-row">
							<div className="sign-up__form-field">
								<label htmlFor="agent_name">Facility Name</label>
								<input
									type="text"
									id="agent_name"
									name="agent_name"
									required
									placeholder="e.g. Marina Bay Sands"
									value={inputs.agent_name}
									onChange={handleInputChange}
								/>
							</div>
							<div className="sign-up__form-field">
								<label htmlFor="password">Password</label>
								<input
									type="password"
									id="password"
									name="password"
									required
									pattern="(?=.*?[A-z])(?=.*?[0-9])(?=.*?[*.!@#$%^&(){}\[\]:;<>,?\/\\~_+\-=|]).{8,}"
									value={inputs.password}
									onChange={handleInputChange}
								/>
							</div>
						</div>

						<div className="sign-up__form-row">
							<span className="sign-up__message">{msg}</span>
							<span className="sign-up__password-requirement">
								Choose a strong password that includes alphabets. numbers, special characters and at least 8 characters.
							</span>
						</div>

						<GoogleReCaptcha action="register_form" />

						<div className="sign-up__form-row">
							<button type="submit" className="btn btn--cta-register">
								{loading && <i className="fa fa-spinner fa-spin"></i>}{' '}
								{type.name == 'trial' ? 'start my 14-day trial' : 'get started'}
							</button>
						</div>

						<div className="sign-up__form-row">
							<span className="sign-up__policy">
								By submitting this form, you agree to our{' '}
								<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
									Privacy Policy
								</a>{' '}
								and{' '}
								<a href={TERMS_AND_CONDITION_LINK} target="_blank" rel="noreferrer">
									Terms & Conditions
								</a>
							</span>
						</div>
					</form>
				</div>
			</Popup>
		</>
	);
}
