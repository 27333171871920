import * as React from "react";

function SvgPreventiveMaintenance(props) {
  return (
    <svg width={17} height={17} {...props}>
      <path
        d="M13.228 5.2l-2.4-2.4 2.4-2.4c-.5-.3-1.1-.4-1.7-.4-2.5 0-4.5 2-4.5 4.5 0 .5.1.9.2 1.4l-6.6 4.9c-.8.6-.8 1.7-.2 2.4l2.3 2.4c.7.7 1.8.6 2.4-.2l4.9-6.6c.5.1 1 .2 1.5.2 2.5 0 4.5-2 4.5-4.5 0-.6-.1-1.2-.3-1.7l-2.5 2.4z"
        fill="#F7654B"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgPreventiveMaintenance;
