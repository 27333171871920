import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 44"}</title>
      <g transform="translate(-101 -2009)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={2009} width={55} height={55} rx={10} />
        <path
          d="M136 2029h-14a.945.945 0 00-1 1v11a.945.945 0 001 1h4l3 3 3-3h4a.945.945 0 001-1v-11a.945.945 0 00-1-1zm-10 9h-2v-5h2v5zm4 1h-2v-7h2v7zm4-2h-2v-3h2v3z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
