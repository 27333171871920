import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <g clipPath="url(#prefix__clip0)" fill="#FF6B4E">
        <path d="M33.936 28.207a.469.469 0 010-.414l.597-1.221c.332-.68.069-1.49-.6-1.845l-1.2-.636a.469.469 0 01-.243-.336l-.235-1.338a1.39 1.39 0 00-1.57-1.14l-1.345.19a.469.469 0 01-.394-.128l-.976-.944a1.389 1.389 0 00-1.94 0l-.976.944a.469.469 0 01-.394.128l-1.346-.19a1.389 1.389 0 00-1.569 1.14l-.235 1.338a.469.469 0 01-.243.336l-1.2.637a1.39 1.39 0 00-.6 1.844l.597 1.22a.469.469 0 010 .415l-.597 1.221c-.332.68-.069 1.49.6 1.845l1.2.636c.13.07.218.191.243.336l.235 1.338a1.388 1.388 0 001.57 1.14l1.345-.19a.469.469 0 01.394.128l.976.944a1.39 1.39 0 001.94 0l.976-.944a.47.47 0 01.394-.128l1.346.19a1.389 1.389 0 001.569-1.14l.235-1.338a.47.47 0 01.243-.336l1.2-.636a1.389 1.389 0 00.6-1.845l-.597-1.22zm-.435 2.25l-1.2.637a1.386 1.386 0 00-.72.991l-.235 1.339a.47.47 0 01-.53.385l-1.346-.19c-.43-.06-.854.077-1.165.379l-.977.944a.47.47 0 01-.656 0l-.977-.945a1.384 1.384 0 00-1.165-.378l-1.345.19a.47.47 0 01-.53-.386l-.236-1.338a1.386 1.386 0 00-.72-.99l-1.2-.638a.47.47 0 01-.203-.624l.597-1.22c.19-.39.19-.836 0-1.226l-.597-1.22a.47.47 0 01.203-.624l1.2-.637c.383-.203.645-.564.72-.991l.235-1.338a.47.47 0 01.53-.386l1.346.19c.43.06.854-.077 1.165-.378l.977-.945a.47.47 0 01.656 0l.977.945c.311.3.736.439 1.165.378l1.345-.19a.47.47 0 01.53.386l.236 1.338c.075.427.337.788.72.99l1.2.638a.47.47 0 01.203.624l-.597 1.22c-.19.39-.19.836 0 1.225l.597 1.221a.47.47 0 01-.203.624z" />
        <path d="M30.371 24.629a.461.461 0 00-.652 0l-6.09 6.09a.461.461 0 10.652.652l6.09-6.09a.461.461 0 000-.652zM25.154 23.847c-.933 0-1.691.76-1.691 1.692 0 .933.758 1.692 1.691 1.692s1.692-.759 1.692-1.692c0-.933-.759-1.692-1.692-1.692zm0 2.461a.77.77 0 010-1.538.77.77 0 010 1.538zM28.846 28.769c-.933 0-1.692.759-1.692 1.692 0 .933.759 1.692 1.692 1.692.933 0 1.691-.76 1.691-1.692 0-.933-.758-1.692-1.691-1.692zm0 2.46a.77.77 0 11.001-1.539.77.77 0 01-.001 1.54z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(19 20)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
