import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 12"}</title>
      <g transform="translate(-968)" fill="none" fillRule="evenodd">
        <path
          d="M1003.285 35.305l-2.578-2.594a1 1 0 00-1.416-.002L997 35l-5-5 2.294-2.294a1 1 0 00.001-1.414l-2.58-2.584a1 1 0 00-1.415 0l-2.296 2.295L988 26c0 7.18 5.82 13 13 13l2.283-2.283a1 1 0 00.002-1.412zM1004 31h-2c0-3.309-2.691-6-6-6v-2c4.411 0 8 3.589 8 8z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M1000 31h-2c0-1.103-.897-2-2-2v-2c2.206 0 4 1.794 4 4z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <rect fill="#FFEAE6" x={968} width={55} height={55} rx={10} />
        <path
          d="M1002.707 27.207L995.5 20H990v2h3.914l7.5 7.5c.068.071.13.147.187.227l1.106-1.106a1 1 0 000-1.414z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M1000.707 30.207L993.5 23H988v5.5l7.207 7.207a1 1 0 001.414 0l4.086-4.086a1 1 0 000-1.414zM991 27a1 1 0 110-2 1 1 0 010 2z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
