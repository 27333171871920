import {
	whatsapp,
	telegram,
	messenger,
	workchat,
	widget,
	ios,
	android,
	line,
	googleChat,
	slack,
	teams,
	email,
	viber,
} from '../../utils/images';

import { IOS_APP, ANDROID_APP } from '../../constants';

import { Tooltip } from 'antd';

export default function ChannelsList() {
	let logos = [
		whatsapp,
		telegram,
		messenger,
		line,
		viber,
		workchat,
		googleChat,
		email,
		widget,
		slack,
		teams,
		ios,
		android,
	];

	return (
		<ul className="demo__channels">
			{logos.map((value, index) => (
				<ChannelsItem key={index} index={index} value={value} />
			))}
		</ul>
	);
}

const ChannelsItem = ({ index, value }) => {
	let names = [
		'Whatsapp',
		'Telegram',
		'Facebook Messenger',
		'Line',
		'Viber',
		'Facebook Workchat',
		'Google Chat',
		'Email',
		'Web Chat Widget and Web Portal',
		'Slack',
		'Microsoft Teams',
		'iOS Mobile App',
		'Android Mobile App',
	];

	return (
		<li key={index} className="demo__channels-item">
			{names[index] == 'iOS Mobile App' ? (
				<Tooltip title={names[index]}>
					<a href={IOS_APP} target="_blank" rel="noreferrer">
						<img src={value} alt={names[index]} />
					</a>
				</Tooltip>
			) : names[index] == 'Android Mobile App' ? (
				<Tooltip title={names[index]}>
					<a href={ANDROID_APP} target="_blank" rel="noreferrer">
						<img src={value} alt={names[index]} />
					</a>
				</Tooltip>
			) : (
				<Tooltip title={names[index]}>
					<img src={value} alt={names[index]} />
				</Tooltip>
			)}
		</li>
	);
};
