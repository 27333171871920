import * as React from 'react';

function SvgLicenses(props) {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
			<g id="surface1">
				<path
					d="M 14.082031 2.664062 C 10.390625 3.199219 9.660156 2.265625 8 0.800781 C 6.339844 2.265625 5.609375 3.199219 1.917969 2.664062 C -0.0507812 12.464844 8 15.199219 8 15.199219 C 8 15.199219 16.050781 12.464844 14.082031 2.664062 Z M 9.976562 10.648438 L 8 9.605469 L 6.023438 10.648438 L 6.398438 8.445312 L 4.800781 6.886719 L 7.011719 6.5625 L 8 4.558594 L 8.988281 6.5625 L 11.199219 6.886719 L 9.601562 8.445312 Z M 9.976562 10.648438 "
					fill="#F8644B"
				></path>
			</g>
		</svg>
	);
}

export default SvgLicenses;
