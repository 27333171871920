import * as React from "react";

function SvgStatistics(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 14H2V9h2.5L6 6.8l4 6L12.5 9H14v5zm0-7h-2.5L10 9.2l-4-6L3.5 7H2V2h12v5z"
        fill="#F7654B"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgStatistics;
