import {
	Commercial,
	Residential,
	Education,
	Industrial,
	Government,
	Hotels,
	Retail,
	SolutionCustomIcon,
} from '../components/Svg';

export const SOLUTION_GROUP = [
	{
		name: 'Facilities Management',
		color: '#e74c3c',
		link: '/',
	},
	{
		name: 'Field Services Management',
		color: '#3498db',
		link: '/features/field_service_management',
	},
];

export const BUILDING_TYPES = [
	{
		group: 1,
		submenu: {
			icon: <Commercial />,
			name: 'Commercial',
		},
		slug: 'commercial',
		heading: {
			title: 'Commercial Buildings',
			description:
				'FacilityBot works great for offices of any type. Delight your office workers with a single messaging-first solution for fault reporting, service requests, visitor management and facilities booking',
		},
		articles: [
			{
				image: 1,
				title: 'No Mobile App Downloads, No Sign Ups, No Logins',
				description:
					'Office workers are busy people. Downloading a new mobile app just to request for Facilities Management services is too much of a hassle. FacilityBot allows everything to be done through messaging channels that they are already familiar with. What could be more seamless?',
			},
			{
				image: 2,
				title: 'A Single Solution That Does So Much',
				description:
					"FacilityBot not only manages all Facilities Management workflows, including technicians, cleaning and security, it can also help with service requests such as season parking and permit to work applications. That's not all, visitor management and facilities booking are also available out-of-the-box in one integrated solution. ",
			},
			{
				image: 3,
				title: 'Better Data Driven Decisions',
				description:
					'Whether Asset replacement decisions, equipment expenditure decisions or facilities management manpower decisions, FacilityBot helps by gathering all required data and presenting them in charts and reports that are highly configurable to aid in your specific data analytics requirements.',
			},
			{
				image: 4,
				title: 'Here to Make Maintaining High Grade Offices So Much Easier',
				description:
					'We understand. Maintaining high grade office buildings requires paying attention to asset quality, minimizing facilities breakdown and most importantly, delighting building users. FacilityBot is here to help in all these areas, from recording response times and automatically gathering user sentiment to tracking asset issues and expenditures. With one single cloud-based solution, we aim to make the work of the commercial_building maintenance team so much easier.',
			},
		],
		plan: 'Find out how FacilityBot can enhance building user satisfaction at your office building',
	},
	{
		group: 1,
		submenu: {
			icon: <Residential />,
			name: 'Residential',
		},
		slug: 'residential',
		heading: {
			title: 'Residential',
			description: 'FacilityBot is a hassle free residential estate management system for all estate types',
		},
		articles: [
			{
				image: 5,
				title: 'Delight Residents with an All-in-One System',
				description:
					"Savvy residents demand an all-in-one and easy to use system for estate management. FacilityBot is designed to meet these standards with numerous features including fault reporting, custom service requests, facilities booking and visitor management. All delivered through residents' preferred messaging channel.",
			},
			{
				image: 6,
				title: 'Manage All Estate Requests and Close The Loop With Residents',
				description:
					"Ensure all fault reports are ticketing and properly dealt with. Automatically update requestors when tickets are updated. Enhance communication with residents using FacilityBot's broadcast and survey features. Allow the chatbot to respond to FAQs automatically.",
			},
			{
				image: 7,
				title: 'Digitize Preventive Maintenance and Track Completion',
				description:
					'Preventive maintenance activities can be easily digitized and their completion tracked. Ensure all checklists are digitally stored for easy retrieval and audit.',
			},
			{
				image: 8,
				title: 'Enhance Procurement Transparency',
				description:
					"FacilityBot's procurement feature allows all authorized parties to view procurement processes, including sending requests for quotations to vendors and quotations submitted by vendors. Procurement activities therefore become transparent and easily audited.",
			},
		],
		plan: 'Find out how FacilityBot can help you manage your residential estate',
	},
	{
		group: 1,
		submenu: {
			icon: <Education />,
			name: 'Education',
		},
		slug: 'education',
		heading: {
			title: 'Education',
			description: 'Managing your campus has never been easier with FacilityBot',
		},
		articles: [
			{
				image: 9,
				title: 'Manage Multiple Locations Seamlessly ',
				description:
					'Whether yours is a sprawling campus with multiple buildings or contained within a single building, FacilityBot will work equally well. With flexible location tagging, multi-location support and easy configuration, FacilityBot is the ideal choice for campuses of all sizes.',
			},
			{
				image: 10,
				title: 'Allow Staff and Students to Make Requests through Messaging Channels',
				description:
					"FacilityBot's messaging-first with omnichannel supports means that staff and students can easily surface fault reports and requests through their preferred messaging channel. Easily involve the campus community and make campus upkeep a collaborative effort.",
			},
			{
				image: 11,
				title: 'Manage Assets Centrally  ',
				description:
					'Maintain a central asset register linked to fault reports, preventive maintenance checklists and expenditures. Know when each asset was last serviced simply by scanning the affixed QR code.',
			},
			{
				image: 12,
				title: 'Automatically Generate Reports for Better Decision-Making',
				description:
					"FacilityBot's powerful data analytics integration allows all maintenance data to be aggregated and presented in highly configurable charts, reports and dashboards for better decision making, including response times, asset health, preventive maintenance completion and expenditures.",
			},
		],
		plan: 'Find out how FacilityBot can help you make your campus a great place to study in',
	},
	{
		group: 1,
		submenu: {
			icon: <Industrial />,
			name: 'Industrial',
		},
		slug: 'industrial',
		heading: {
			title: 'Industrial Buildings',
			description: 'Equipment uptime is crucial in manufacturing plants and industrial building. FacilityBot can help.',
		},
		articles: [
			{
				image: 13,
				title: 'Digitize Preventive Maintenance Checklists',
				description:
					'Schedule preventive maintenance is critical in minimizing equipment downtime. Easily digitize your preventive maintenance checklists with FacilityBot and easily they are all satisfactorily completed.',
			},
			{
				image: 14,
				title: 'Handle Any Type of Fault Report or Service Requests',
				description:
					'Each manufacturing or industrial facility has its own unique fault report and service request types. We understand this. This is why we built FacilityBot to be highly configurable with an in-built form builder to cater to manufacturing or industrial facilities of any type.',
			},
			{
				image: 15,
				title: 'Easy Integration to Sensors',
				description:
					"Advanced manufacturing or industrial buildings may deploy sensors to automatically detect faults. FacilityBot is integration-friendly and acts as a workflow backbone to aggregate alerts from numerous equipment and multiple sensor types, so you don't have to monitor multiple systems simultaneously. ",
			},
			{
				image: 16,
				title: 'Audit Trails To Maintain Workplace Safety Standards ',
				description:
					'FacilityBot supports best-in-class workforce safety procedures by capturing detailed documentation of hazard identification, fault rectification and preventive maintenance work, including capturing photo evidence of completion and workflow histories.',
			},
		],
		plan: 'Find out how FacilityBot can help you manage your industrial building',
	},
	{
		group: 1,
		submenu: {
			icon: <Government />,
			name: 'Government',
		},
		slug: 'government',
		heading: {
			title: 'Government',
			description: 'Ensure your Facilities Management process adhere to best in class audit standards',
		},
		articles: [
			{
				image: 17,
				title: 'Track Response Times and Gather Photo Evidence of Task Completion',
				description:
					'Maintenance processes in Government buildings should be efficient, thorough and well-documented. FacilityBot eliminates workflow inefficiencies through auto assignment, mobile-first communications and real-time central tracking of requests status. All stages in the workflow are digitally captured and stored for monitoring and audit.',
			},
			{
				image: 18,
				title: 'Manage Procurement Activities and Expenditure',
				description:
					"FacilityBot's procurement feature allows all authorized parties to view procurement processes, including sending requests for quotations to vendors and quotations submitted by vendors. Procurement activities therefore become transparent and easily audited.",
			},
			{
				image: 19,
				title: 'Digitize Preventive Maintenance And Track Completion ',
				description:
					'All scheduled maintenance activities can be digitized and tracked centrally by management. Photos can be uploaded as evidence of completion. Ensuring every room is well maintained is now much easier.',
			},
			{
				image: 20,
				title: 'Automatically Generate Reports for Better Decision-Making',
				description:
					"FacilityBot's powerful data analytics integration allows all maintenance data to be aggregated and presented in highly configurable charts, reports and dashboards for better decision making, including response times, asset health, preventive maintenance completion and expenditures.",
			},
		],
		plan: 'Find out how FacilityBot can help you manage government buildings',
	},
	{
		group: 1,
		submenu: {
			icon: <Hotels />,
			name: 'Hotels',
		},
		slug: 'hotels',
		heading: {
			title: 'Hotels',
			description: 'Maintaining the highest service standards levels just got easier with FacilityBot ',
		},
		articles: [
			{
				image: 21,
				title: 'Automate Workflows and Track Response Times',
				description:
					'Guests demand the highest service standards, including ensuring that all amenities are in good working condition. FacilityBot eliminates workflow inefficiencies through auto assignment, mobile-first communications and real-time central tracking of requests status.',
			},
			{
				image: 22,
				title: 'Easily View Photo Evidence Of Task Completion   ',
				description:
					'All requests are ticketed. Fault related photos and completion photos can be easily uploaded and viewed to ensure tasks are satisfactorily completed',
			},
			{
				image: 23,
				title: 'Digitize Preventive Maintenance And Track Completion',
				description:
					'All scheduled maintenance activities can be digitized and tracked centrally by management. Photos can be uploaded as evidence of completion. Ensuring every room is well maintained is now much easier.',
			},
			{
				image: 24,
				title: 'Aggregate and Analyze Data Across Multiple Locations',
				description:
					'FacilityBot supports hotels chains with multiple locations. Ensure data from all locations are of the same format and easily compare data across location. FacilityBot has a powerful data analytics integration that allows data from multiple locations to be aggregated and reports and dashboards to be easily configured.',
			},
		],
		plan: 'Find out how FacilityBot can help you manage your hotels and delight your guests',
	},
	{
		group: 1,
		submenu: {
			icon: <Retail />,
			name: 'Retail',
		},
		slug: 'retail',
		heading: {
			title: 'Retail Chains',
			description: 'Managing facilities in multiple locations has never been easier with FacilityBot',
		},
		articles: [
			{
				image: 25,
				title: 'Automate Workflows and Track Response Times',
				description:
					'Guests demand the highest service standards, including ensuring that all amenities are in good working condition. FacilityBot eliminates workflow inefficiencies through auto assignment, mobile-first communications and real-time central tracking of requests status.',
			},
			{
				image: 26,
				title: 'Manage Procurement and Expenditure Across Multiple Locations',
				description:
					'Retail store managers work with some autonomy but should also adhere to central HQ procurement processes and use approved vendors. FacilityBot helps achieve the right balance.',
			},
			{
				image: 27,
				title: 'Digitize Preventive Maintenance And Track Completion',
				description:
					'All preventive maintenance activities in each store can be digitized and completion can be centrally tracked. Managing preventive maintenance checklists over multiple locations no longer needs to be a messy affair.',
			},
			{
				image: 28,
				title: 'Aggregate and Analyze Data Across Multiple Locations',
				description:
					'FacilityBot allows aggregated data across all your retail stores to be easily compared, including request types, response times, expenditures and preventive maintenance activities.',
			},
		],
		plan: 'Find out how FacilityBot can help you manage retail locations',
	},

	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="spray-can" />,
			name: 'Cleaning',
		},
		slug: 'cleaning',
		heading: {
			title: 'Cleaning',
			description: 'Digitize your cleaning operations with one single platform',
		},
		articles: [
			{
				image: 8,
				title: 'Fault Reporting and Checklists',
				description:
					'Handling fault reports from building users and digitizing regular cleaning checklists using FacilityBot is a breeze. Cleaners can respond using the FacilityBot mobile app and upload images when they complete tasks and checklists. Impress your clients with a new level of digital service, increase efficiency, maintain digital records, and produce reports with one easy-to-use single platform.',
			},
			{
				image: 9,
				title: 'Attendance and Live Location',
				description:
					'Use FacilityBot as your attendance system. Cleaners can check in and out daily at each site using their mobile app, generating an attendance record. FacilityBot can also provide live location information of the cleaners displayed on a map.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your cleaning operations. FacilityBot integrates easily with cleaning robots, bin sensors, people counters, ammonia detectors and other sensors that can help boost your cleaners productivity.',
			},
			{
				image: 28,
				title: 'Toilet Feedback',
				description:
					"FacilityBot natively supports toilet feedback panels that allow users to give their feedback and highlight typical issues in Toilets. Easily deploy toilet feedback forms on any tablet or use QR codes, which when scanned, displays the form on the users' mobile phones.",
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your cleaning operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="tree" />,
			name: 'Landscaping',
		},
		slug: 'landscaping',
		heading: {
			title: 'Landscaping',
			description: 'Digitize your landscaping operations with one single platform',
		},
		articles: [
			{
				image: 17,
				title: 'Mobile Checklists and Service Reports',
				description:
					'Using the FacilityBot Mobile App, your landscaping technicians can easily upload multiple images to checklists to provide evidence of their work. Service Reports can be automatically generated from checklists and emailed to customers.',
			},
			{
				image: 26,
				title: 'Scheduling and Live Locations',
				description:
					'Easily schedule the daily tasks of landscaping technicians through our cloud-based web platform. The technicians will see their daily schedules on the FacilityBot Mobile App. View their live locations at any time and reschedule on the fly.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your landscaping operations. FacilityBot integrates easily with people counters, soil, temperature and other sensors.',
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your landscaping operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="bug" />,
			name: 'Pest Control',
		},
		slug: 'pest-control',
		heading: {
			title: 'Pest Control',
			description: 'Digitize your pest control operations with one single platform',
		},
		articles: [
			{
				image: 13,
				title: 'Inspection Checklists',
				description:
					'Easily create inspection checklists to support your pest control operations. Set the recurrence period for each checklist and an inspection calendar will be automatically produced. Complete checklists using the FacilityBot Mobile App and insert images as evidence on the move. Automatically generate and email service reports to your clients.',
			},
			{
				image: 24,
				title: 'Attendance and Live Location',
				description:
					'Use FacilityBot as your attendance system. Pest control technicians can check in and out at each site using their mobile app, generating an attendance record. FacilityBot can also provide live location information of the pest control technicians displayed on a map.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your pest control operations. FacilityBot integrates easily with smart traps and other sensors that can help impress your customers and improve your operational efficiency.',
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your pest control operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="cogs" />,
			name: 'Mechanical and Electrical',
		},
		slug: 'mechanical-electrical',
		heading: {
			title: 'Mechanical and Electrical',
			description: 'Whether you maintain HVAC, Lifts or specialist M&E systems, FacilityBot is here to support you',
		},
		articles: [
			{
				image: 13,
				title: 'Inspection Checklists',
				description:
					'Easily create digital inspection checklists to support your maintenance operations. Set the recurrence period for each checklist and an inspecion calendar will be automatically produced. Technicians complete the checklists on the move using the FacilityBot Mobile App, including inserting images as evidence. Automatically generate and email service reports to your clients.',
			},
			{
				image: 24,
				title: 'Attendance and Live Location',
				description:
					'Use FacilityBot as your attendance system. Technicians can check in and out at each site using their mobile app, generating an attendance record. FacilityBot can also provide live location information of the technicians displayed on a map.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your maintenance operations. FacilityBot integrates easily with current, temperature and other sensors to immediately trigger service tickets and alert technicians to respond.',
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your Mechanical and Electrical maintenance operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="medkit" />,
			name: 'Medical Equipment',
		},
		slug: 'medical-equipment',
		heading: {
			title: 'Medical Equipment',
			description: 'Digitize your medical equipment maintenance operations with one single platform',
		},
		articles: [
			{
				image: 13,
				title: 'Inspection Checklists',
				description:
					'Easily create digital inspection checklists to support your maintenance operations. Set the recurrence period for each checklist and an inspection calendar will be automatically produced. Technicians complete the checklists on the move using the FacilityBot Mobile App, including inserting images as evidence. Automatically generate and email service reports to your clients.',
			},
			{
				image: 24,
				title: 'Attendance and Live Location',
				description:
					'Use FacilityBot as your attendance system. Technicians can check in and out at each site using their mobile app, generating an attendance record. FacilityBot can also provide live location information of the technicians displayed on a map.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your maintenance operations. FacilityBot integrates easily with current, temperature and other sensors to immediately trigger service tickets and alert technicians to respond.',
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your Medical Equipment maintenance operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
	{
		group: 2,
		submenu: {
			icon: <SolutionCustomIcon name="volume-up" />,
			name: 'Audio and Visual',
		},
		slug: 'audio-visual',
		heading: {
			title: 'Audio and Visual',
			description: 'Digitize your audio and visual maintenance operations with one single platform',
		},
		articles: [
			{
				image: 13,
				title: 'Inspection Checklists',
				description:
					'Easily create digital inspection checklists to support your maintenance operations. Set the recurrence period for each checklist and an inspecion calendar will be automatically produced. Technicians complete the checklists on the move using the FacilityBot Mobile App, including inserting images as evidence. Automatically generate and email service reports to your clients.',
			},
			{
				image: 24,
				title: 'Attendance and Live Location',
				description:
					'Use FacilityBot as your attendance system. Technicians can check in and out at each site using their mobile app, generating an attendance record. FacilityBot can also provide live location information of the technicians displayed on a map.',
			},
			{
				image: 15,
				title: 'Sensor Integration',
				description:
					'Keep ahead of the pack by deploying Internet of Things (IoT) sensors that augment your maintenance operations. FacilityBot integrates easily with current, temperature and other sensors to immediately trigger service tickets and alert technicians to respond.',
			},
		],
		plan: 'Find out how FacilityBot can help you digitize and automate your Audio and Visual Equipment maintenance operations',
		footer: 'Digitize and Automate your field service operations with one single platform',
	},
];
