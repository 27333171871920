import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 27"}</title>
      <g transform="translate(-741 -1080)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={1080} width={55} height={55} rx={10} />
        <path
          d="M776.135 1106.784a2 2 0 01-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094a2 2 0 01-2.969-1.23c-.151-.607-.547-.865-.997-.865h-.438c-.45 0-.845.258-.997.865a2 2 0 01-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 01-1.23 2.969c-.605.151-.865.546-.865.997v.438c0 .45.258.845.865.997a2 2 0 011.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 012.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 012.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.78.094-1.316a2 2 0 011.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997zM769 1111a3 3 0 110-6 3 3 0 010 6z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
