import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <path
        d="M33 20H22.333v1.333H33V20zM22.333 36H33v-1.333H22.333V36zM33 22.667H22.333c-.733 0-1.333.6-1.333 1.333v8c0 .733.6 1.333 1.333 1.333H33c.733 0 1.333-.6 1.333-1.333v-8c0-.733-.6-1.333-1.333-1.333zM27.667 24.5c.826 0 1.5.673 1.5 1.5s-.674 1.5-1.5 1.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5zM31 31.333h-6.667v-1c0-1.113 2.22-1.666 3.334-1.666 1.113 0 3.333.553 3.333 1.666v1z"
        fill="#FF6B4E"
      />
    </svg>
  )
}

export default SvgComponent
