import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M32.4 29.7l1.2-1.2c.9-.9 1.4-2.2 1.4-3.5 0-1.3-.5-2.6-1.5-3.5-.9-1-2.2-1.5-3.5-1.5-1.3 0-2.6.5-3.5 1.5l-1.2 1.1c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l1.2-1.2c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.4.9 2.2 0 .8-.3 1.6-.9 2.1L31 28.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3zM27.3 32l-1.2 1.2c-1.1 1.1-3.1 1.1-4.2 0-.6-.6-.9-1.4-.9-2.2 0-.8.3-1.6.9-2.1l1.1-1.2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-1.1 1.2c-1 .9-1.5 2.2-1.5 3.5 0 1.3.5 2.6 1.5 3.5.9 1 2.2 1.5 3.5 1.5 1.3 0 2.6-.5 3.5-1.5l1.2-1.2c.4-.4.4-1 0-1.4-.4-.4-1-.3-1.4.1z"
        fill="#F8644B"
      />
      <path
        d="M28.4 25.2l-4.2 4.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3l4.2-4.2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0zM21.3 23.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-2-2c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2zM32.7 32.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-2-2z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
