import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 39"}</title>
      <g transform="translate(-1069 -1782)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={1782} width={55} height={55} rx={10} />
        <g transform="translate(1090 1803)" fill="#F7654B" fillRule="nonzero">
          <circle cx={3} cy={3} r={3} />
          <circle cx={11} cy={3} r={3} />
          <circle cx={3} cy={11} r={3} />
          <circle cx={11} cy={11} r={3} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
