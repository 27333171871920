import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 48"}</title>
      <g transform="translate(-101 -2236)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={2236} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M129 2256c-2.206 0-4 1.794-4 4v1c0 2.206 1.794 4 4 4s4-1.794 4-4v-1c0-2.206-1.794-4-4-4zM133.036 2265.426A5.969 5.969 0 01129 2267c-1.555 0-2.969-.6-4.036-1.574-1.761.574-2.964 1.885-2.964 3.574v3h14v-3c0-1.689-1.203-3-2.964-3.574z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
