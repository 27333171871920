import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12a3 3 0 116 0 3 3 0 01-6 0zm2 0a1 1 0 102 0 1 1 0 00-2 0z"
        fill="#F7654B"
      />
      <path
        d="M10 10V6l-6 4-2-4H0v9c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V6l-6 4zM13 4c0-1.7-1.3-3-3-3-1 0-1.9.5-2.4 1.2C6.9.9 5.6 0 4 0 1.8 0 0 1.8 0 4h13z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
