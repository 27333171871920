import axios from 'axios';

const API_KEY_PUBLIC = process.env.NEXT_PUBLIC_MAILJET_API_KEY;
const API_KEY_PRIVATE = process.env.NEXT_PUBLIC_MAILJET_SECRECT_API_KEY;

export const interestedLeadsListId = '10329030';

export default axios.create({
	baseURL: `/mailjet-api/`,
	responseType: 'json',
	auth: {
		username: API_KEY_PUBLIC,
		password: API_KEY_PRIVATE,
	},
	headers: {
		'Content-Type': 'application/json',
	},
});
