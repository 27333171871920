import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 58"}</title>
      <g transform="translate(-1069 -2734)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={2734} width={55} height={55} rx={10} />
        <path
          d="M1095 2753c-.6 0-1 .4-1 1v1.1c-3.4.5-6 3.4-6 6.9 0 3.9 3.1 7 7 7 2 0 3.8-.8 5-2.2l.9.6c.6.4 1.2 0 1.4-.2 1.1-1.5 1.7-3.3 1.7-5.2 0-5-4-9-9-9zm0 14c-2.8 0-5-2.2-5-5 0-2.4 1.7-4.4 4-4.9v4.9c0 .3.2.6.4.8l4 2.8c-.9.9-2.1 1.4-3.4 1.4z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
