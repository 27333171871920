import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 14"}</title>
      <g transform="translate(-326)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={326} width={55} height={55} rx={10} />
        <path
          d="M358 19h-8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V21c0-1.103-.897-2-2-2zm-4 14a1 1 0 110-2 1 1 0 010 2zm4-4h-8v-8h8v8z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
