import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <path
        d="M32.714 25.571H19v2.286h13.714v-2.286zm0-4.571H19v2.286h13.714V21zM19 32.429h9.143v-2.286H19v2.286zm22.286-5.143L43 29l-7.989 8-5.154-5.143 1.714-1.714 3.44 3.428 6.275-6.285z"
        fill="#FF6B4E"
      />
    </svg>
  )
}

export default SvgComponent
