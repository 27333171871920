import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 41"}</title>
      <g transform="translate(-101 -1782)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={1782} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M127 1802h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 5h-3v-3h3v3zM136 1811h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 5h-3v-3h3v3zM127 1811h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 5h-3v-3h3v3zM132 1809h.055a1 1 0 00.726-.373l4-5a1 1 0 10-1.562-1.252l-3.302 4.125-1.21-1.21a1 1 0 10-1.414 1.417l2 2a1 1 0 00.707.293z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
