import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={14} viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M2.75 4a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM13.25 4a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM13.5 5a4.761 4.761 0 00-3.474 2.032c-.266.315-.56.606-.879.868a1.666 1.666 0 01.331 1.558A5.228 5.228 0 0011 8.191V13a1 1 0 001 1h3a1 1 0 001-1V7.5A2.5 2.5 0 0013.5 5zM7.8 8.25c-.693 0-1.144-.463-1.823-1.218A4.76 4.76 0 002.5 5 2.5 2.5 0 000 7.5V13a1 1 0 001 1h3a1 1 0 001-1V8.191A3.717 3.717 0 007.8 9.75a.75.75 0 000-1.5z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
