import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 50"}</title>
      <g transform="translate(-1069 -2236)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={2236} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1097 2262c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zM1099 2272h-4v-4l-2-2v-3h8v3l-2 2zM1091 2261c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM1092 2266.4v-4.4h-3v3l1 1v4h3v-2.6zM1103 2261c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM1102 2266.4v-4.4h3v3l-1 1v4h-3v-2.6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
