import * as React from 'react';

const SvgSensorMaps = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		version="1.2"
		baseProfile="tiny"
		viewBox="0 0 220 220"
		overflow="visible"
		width="1em"
		height="1em"
		fill="currentColor"
		aria-hidden="true"
		focusable="false"
		{...props}
	>
		<g id="logos">
			<path
				fill="#F7654B"
				d="M34.9,144c-0.2,0-0.4,0-0.6,0v77.6c0,5.6,4.6,10.2,10.2,10.2h79.9C123.7,183.3,83.8,144,34.9,144z M34.9,80c-0.2,0-0.4,0-0.6,0v33c65.9,0.3,119.5,53.3,120.2,118.8h34.2C188.1,148,119.3,80,34.9,80z M237.2,221.7v-70.1C214,94.8,167.3,50,109.1,29H44.5c-5.6,0-10.2,4.6-10.2,10.2V49c101.4,0.3,183.9,82,184.5,182.8h8.2C232.6,231.8,237.2,227.3,237.2,221.7z M210.5,57.3c9.4,9.4,19,21.3,26.7,31.8v-50c0-5.6-4.5-10.1-10.1-10.1h-51.5C187.5,37.3,199.9,46.8,210.5,57.3z"
			/>
		</g>
	</svg>
);

export default SvgSensorMaps;
