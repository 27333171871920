//blog links
export const BLOG_LINK = 'https://blog.facilitybot.co/blog';
export const CONTACT_LINK = 'https://blog.facilitybot.co/contact-us';
export const ROI_METHODOLOGY_LINK =
	'https://blog.facilitybot.co/what-is-the-roi-return-on-investment-of-implementing-facilitybot';
export const TERMS_AND_CONDITION_LINK = 'https://blog.facilitybot.co/software-service-agreement';
export const PRIVACY_POLICY_LINK = 'https://blog.facilitybot.co/privacy-policy';
export const REFERRAL_TERMS_LINK = 'https://blog.facilitybot.co/referral-terms-and-conditions/';
export const KNOWLEDGE_BASE_LINK = 'https://blog.facilitybot.co/knowledge-base/';
export const SME_LINK = 'https://blog.facilitybot.co/facilitybot-smes-go-digital/';
export const AGREEMENT_POLICY_LINK = 'https://blog.facilitybot.co/channel-partner-agreement/';
export const CASE_STUDIES_LINK = 'https://blog.facilitybot.co/case-studies';
export const GLOSSARY_LINK = 'https://blog.facilitybot.co/glossary-of-maintenance-terms/';

//store link
export const STORE_LINK = 'https://store.facilitybot.co/';

//calendly link
export const CALENDLY_LINK = 'https://calendly.com/facilitybot/facilitybot-demo';

//resource links
export const API_DOCUMENTATION_LINK = 'https://documenter.getpostman.com/view/187698/2sA358cR2w';
export const SIGN_IN_LINK = 'https://signin.facilitybot.co';
export const FACILITY_BOT_ACADEMY_LINK = 'https://facilitybot.teachable.com/p/home';
export const CANNY_WIDGET_LINK = 'https://facilitybot.canny.io';

//social media channels
export const FACEBOOK_LINK = 'https://www.facebook.com/FacilityBot/';
export const TWITTER_LINK = 'https://twitter.com/FacilityBot';
export const YOUTUBE_LINK = 'https://www.youtube.com/c/facilitybot';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/31130457';

//homepage video
export const EXPLAINER = 'https://www.youtube.com/watch?v=Sgwh5PTHxEA';

//feature videos
export const CUSTOM_SERVICE_REQUESTS = 'https://www.youtube.com/watch?v=T3qBHjXnahc';
export const PREVENTIVE_MAINTENANCE_CHECKLIST = 'https://www.youtube.com/watch?v=n2ADQ-mPKbQ';
export const FACILITY_BOOKING = 'https://www.youtube.com/watch?v=IbdtVnfIkGU';
export const QR_CODE_SCANNING = 'https://www.youtube.com/watch?v=kW8HmB1i1vc';
export const ASSET_MANAGEMENT = 'https://www.youtube.com/watch?v=QUJy67_aEzM';
export const CONFIGURABLE_WORKFLOWS = 'https://www.youtube.com/watch?v=8M82qsm2yPU';
export const AUTOMATED_FAQ = 'https://www.youtube.com/watch?v=YMjc0dwknBI';
export const BROADCAST = 'https://www.youtube.com/watch?v=BulrjKBvRMk';
export const PHOTO_AND_VIDEO_UPLOAD = 'https://www.youtube.com/watch?v=Xk4olWyEu_o';
export const ONLINE_SURVEY = 'https://www.youtube.com/watch?v=Kr8odfIsrfo';
export const PROPERTY_PORTFOLIO = 'https://www.youtube.com/watch?v=10amC5h-xMI';

export const FAULT_REPORTING = 'https://www.youtube.com/watch?v=3o5-1L_1RfM';
export const STATISTICS = 'https://www.youtube.com/watch?v=UgustQ3Hfug';
export const EXPENDITURES = 'https://www.youtube.com/watch?v=WMLIIWCvBZ8';
export const VISITOR_MANAGEMENT = 'https://www.youtube.com/watch?v=AwuCDRTgJJs';
export const PROCUREMENT = 'https://www.youtube.com/watch?v=TI8uBGoZlaY';
export const SCHEDULE_OF_RATES = 'https://www.youtube.com/watch?v=HsGDp7N1sI0';
export const METERS = 'https://www.youtube.com/watch?v=3ZjA4Zo0n9M';
export const PARTS = 'https://www.youtube.com/watch?v=eiOTLKhjG8w';
export const ATTENDANCE = 'https://www.youtube.com/watch?v=TM747y5v1JE';
export const VENDOR_MANAGEMENT = 'https://www.youtube.com/watch?v=xCTr2fyN07M';
export const CUSTOM_DASHBOARD = 'https://www.youtube.com/watch?v=LRJVktnqaio';
export const MAP_DASHBOARD = 'https://www.youtube.com/watch?v=LRJVktnqaio'; // to be updated later
export const QR_CODE_FAULT_REPORTING = 'https://www.youtube.com/watch?v=E2UaUoxG-Qw';
export const SENSOR_MAPS = 'https://www.youtube.com/watch?v=-HnHcM8o8Tc&feature=youtu.be';
export const DELIVERIES = 'https://www.youtube.com/watch?v=sa_BM-6q27c';

export const MULTI_LANGUAGE = 'https://www.youtube.com/watch?v=a0bU_3ee2HA';
export const DOCUMENT_MANAGEMENT = 'https://www.youtube.com/watch?v=gpKFjRfjgc0';
export const IOT_SENSORS_INTEGRATION = 'https://www.youtube.com/watch?v=CsCT7jmnasQ';
export const BILLING = 'https://www.youtube.com/watch?v=pkqtwlaibSA';
export const DATA_ANALYTICS = 'https://www.youtube.com/watch?v=uV0j_yoe9Fk';
export const LICENSES = 'https://www.youtube.com/watch?v=i3_y6zv-_fc';
export const BUDGET = 'https://www.youtube.com/watch?v=8FSd8M83NXw';
export const TENANT_PORTAL = 'https://www.youtube.com/watch?v=vxbojxlWZ1A';
export const CUSTOMERS = 'https://www.youtube.com/watch?v=8yaf5iCAuQ8';
export const SCHEDULING = 'https://www.youtube.com/watch?v=tkeL-hUyf-4';
export const VIRTUAL_DEMO = 'https://www.youtube.com/watch?v=znreIT3wwyQ&feature=youtu.be';
export const FIELD_SERVICE_MANAGEMENT = 'https://www.youtube.com/watch?v=ctJNJVm0Uls';
export const CONTRACTS = 'https://www.youtube.com/watch?v=vvSjtmIQqBM';
export const RESPONDER_LIVE_LOCATION = 'https://www.youtube.com/watch?v=UUrN7gTan6I';
export const BIM = 'https://www.youtube.com/watch?v=82d8XbRWCy0';
export const PERMIT_TO_WORK = 'https://www.youtube.com/watch?v=DhY-jSsG4nI';

export const BMS_INTEGRATION = '';
export const PAYMENTS = '';

//resources videos
export const QUICK_SET_UP = 'https://www.youtube.com/watch?v=dOHYOl8tVpQ';

//app link
export const IOS_APP = 'https://apps.apple.com/sg/app/facility-bot/id1393809491';
export const ANDROID_APP = 'https://play.google.com/store/apps/details?id=com.rth.facilitybot.staff&hl=en_SG';

// Slack
export const SLACK_INVITE_LINK =
	'https://slack.com/oauth/v2/authorize?client_id=3417919481.3536096535158&scope=im:history,users:read,files:read,chat:write,users:read.email&state=';

// Google Form Links

export const QUESTIONNAIRE_INTEREST =
	'https://docs.google.com/forms/d/e/1FAIpQLSfxJ6cPzyRibBmGDFleMTmpbML_w10FYMXTX2NNACO06AES9Q/viewform';
