import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 11h-3V8L9 4.8V3h2V0H7v4.8L3 8v3H0v5h6v-4h4v4h6v-5z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
