import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 55"}</title>
      <g transform="translate(-741 -2734)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={2734} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M767 2754h4v4h-4zM773 2766h4v4h-4zM761 2766h4v4h-4zM764 2763h10v2h2v-3c0-.6-.4-1-1-1h-5v-2h-2v2h-5c-.6 0-1 .4-1 1v3h2v-2zM766 2767h6v2h-6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
