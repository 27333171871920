import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 52"}</title>
      <g transform="translate(-101 -2483)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={2483} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M134 2519h-12a1 1 0 01-1-1v-12a1 1 0 011-1h7v2h-6v10h10v-6h2v7a1 1 0 01-1 1z" />
          <path d="M137 2505h-2v-2h-2v2h-2v2h2v2h2v-2h2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
