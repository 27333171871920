import * as React from "react";

function SvgSurveys(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M6 0H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V1a1 1 0 00-1-1zM5 5H2V2h3v3zM15 9h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 5h-3v-3h3v3zM6 9H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 5H2v-3h3v3zM11 7h.055a1 1 0 00.726-.373l4-5A1 1 0 1014.219.375L10.917 4.5l-1.21-1.21a1 1 0 10-1.414 1.417l2 2A1 1 0 0011 7z" />
      </g>
    </svg>
  );
}

export default SvgSurveys;
