import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M26.548 28.55l.634-1.805a8.593 8.593 0 01-1.875-.828 7.422 7.422 0 001.109-1.617c.311-.611.541-1.26.684-1.931.589 0 .979.02 1.419.06V20.62c-.481.052-.966.075-1.45.07h-2.382v-.61c0-.205.02-.41.06-.61h-2.009c.041.204.061.412.06.62v.6h-2.209c-.484.005-.968-.018-1.449-.07v1.809c.5-.04.839-.06 1.429-.06.17.592.4 1.165.69 1.709.35.663.782 1.277 1.289 1.829A10.892 10.892 0 0119 27.216c.42.53.745 1.128.959 1.769a12.814 12.814 0 003.979-1.839c.806.578 1.684 1.05 2.61 1.404zm-4.06-6.181h2.649a5.635 5.635 0 01-1.25 2.418 5.7 5.7 0 01-1.399-2.418zM32.717 35l-.725-2.379h-3.643L27.624 35h-2.283l3.527-10.035h2.591L35 35h-2.283zm-1.231-4.156l-1.131-3.657a10.295 10.295 0 01-.181-.67c-.15.583-.581 2.025-1.292 4.327h2.604z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
