import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 37"}</title>
      <g transform="translate(-101 -1548)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={1548} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M136 1577h-14c-.6 0-1 .4-1 1v6h2v-4h1v2h2v-2h6v2h2v-2h1v4h2v-6c0-.6-.4-1-1-1zM123 1575h12v-6c0-.6-.4-1-1-1h-10c-.6 0-1 .4-1 1v6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
