import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 33"}</title>
      <g transform="translate(-427 -1314)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={1314} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M448 1350c-.6 0-1-.4-1-1v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 .6-.4 1-1 1zM462 1345h-10c-.6 0-1-.4-1-1v-8c0-.6.4-1 1-1h10c.7 0 1.2.8.9 1.4l-1.8 3.6 1.8 3.6c.3.6-.2 1.4-.9 1.4zm-9-2h7.4l-1.3-2.6c-.1-.3-.1-.6 0-.9l1.3-2.5H453v6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
