import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 3V1a1 1 0 00-1-1H3a1 1 0 00-1 1v2L0 6h16l-2-3zM2 8v7a1 1 0 001 1h3v-4h4v4h3a1 1 0 001-1V8H2z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
