import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 49"}</title>
      <g transform="translate(-427 -2236)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={2236} width={55} height={55} rx={10} />
        <g transform="translate(447 2256)" fill="#F7654B" fillRule="nonzero">
          <path d="M15 4H1a.945.945 0 00-1 1v10a.945.945 0 001 1h14a.945.945 0 001-1V5a.945.945 0 00-1-1zm-1 10H2V6h12v8zM2 0h12v2H2z" />
          <path d="M5 13l5-5 3 5z" />
          <circle cx={5} cy={9} r={1} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
