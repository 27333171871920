import { userImg, messagingImg, integrationImg } from '../../utils/images';

const ADVANTAGES = [
	{
		img: userImg,
		title: 'Great User Experience',
		content: 'By focusing on providing a great user experience, FacilityBot:',
		additional_content: [
			'Minimizes the need for training',
			'Maximizes usage by all parties',
			'Shortens deployment time',
		],
	},
	{
		img: messagingImg,
		title: 'Mobile & Messaging-First',
		content: 'Designed to make interaction as convenient as possible for all users',
	},
	{
		img: integrationImg,
		title: 'Open to Integrations',
		content:
			'It is super easy to connect IoT Sensors to FacilityBot. FacilityBot also connects through Zapier and APIs.',
	},
];

export default function VsCompetitorAdvantages() {
	return (
		<section className="container versus-competitor-advantages">
			<h2 className="versus-competitor-advantages__title">
				3 Reasons to Choose <span style={{ color: 'var(--primary' }}>facility</span>bot
			</h2>

			<div className="versus-competitor-advantages__list">
				{ADVANTAGES.map(({ img, title, content, additional_content }, i) => {
					return (
						<div className="versus-competitor-advantages__item" key={i}>
							<img className="versus-competitor-advantages__item-img" src={img} alt="" />

							<span className="versus-competitor-advantages__item-title">{title}</span>

							<div
								className={`versus-competitor-advantages__item-content ${
									i === 0 ? 'versus-competitor-advantages__item-content--left' : ''
								}`}
							>
								{content}

								{additional_content && (
									<ul
										style={{
											listStyle: 'disc',
											listStylePosition: 'inside',
											marginTop: '2rem',
										}}
									>
										{additional_content.map((item, _) => (
											<li>{item}</li>
										))}
									</ul>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
}
