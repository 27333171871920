import { useState } from 'react';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from '../../constants';
import facilityBotApi from '../../services/facilityBot';
import Popup from '../Popup';
import { openNotification } from '../../utils';
import mailjetService, { interestedLeadsListId } from '../../services/mailjetService';
import senderService from '../../services/senderService';
import { SENDER_GROUPS } from '../../constants/sender';

export default function DownloadChecklistPdfForm({ classNameBtn, checklist, isChecklistGenerator }) {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	let initialInputs = {
		name: '',
		email: '',
	};

	const [inputs, setInputs] = useState(initialInputs);

	const handleInputChange = (e) => {
		e.persist();

		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const clearState = () => {
		setLoading(false);
		setInputs({ ...initialInputs });
	};

	const handleSubmit = async (e) => {
		setLoading(true);

		try {
			e.preventDefault();

			if (!isChecklistGenerator) {
				await facilityBotApi.get(`/clients/public_checklists/${checklist.id}/download_count`);
			}

			// will drop the user information into Interested Leads contact list in mailjet
			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Name: inputs.name,
				Properties: {
					name: inputs.name,
					jobtitle: checklist.title,
				},
				Action: 'addnoforce',
				Email: inputs.email,
			});

			await senderService.post('/subscribers', {
				email: inputs.email,
				firstname: inputs.name,
				groups: [SENDER_GROUPS.CHECKLIST_DOWNLOADS],
			});

			let downloadBtn = document.createElement('a');
			downloadBtn.href = `data:application/pdf;base64, ${!isChecklistGenerator ? checklist.pdf_data : checklist.file}`;
			downloadBtn.download = checklist.title;
			downloadBtn.click();
		} catch (err) {
			const { data } = err.response;
			openNotification('error', 'Error', data.message);
		} finally {
			setLoading(false);
			setTimeout(() => {
				setVisible(false);
			}, 2000);
		}
	};

	return (
		<>
			<button className={classNameBtn} onClick={() => setVisible(!visible)}>
				Download PDF
			</button>
			<Popup visible={visible} onCancel={() => setVisible(!visible)} afterClose={() => clearState()} modalWidth="800px">
				<div className="download-checklist">
					<div className="download-checklist__right">
						<div className="download-checklist__title-wrapper">
							<h1 className="download-checklist__title">
								<span>Facility</span>Bot - {checklist.title}
							</h1>
							<h2 className="download-checklist__title">Thanks for your interest in FacilityBot's public checklists</h2>
							<h3 className="download-checklist__subtitle">
								Please feel free to arrange a demo if you need more info on how to use the checklist in FacilityBot
							</h3>
						</div>
						<form className="download-checklist__form" onSubmit={handleSubmit}>
							<div className="download-checklist__form-field">
								<label htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									required
									placeholder="Name"
									value={inputs.name}
									onChange={handleInputChange}
								/>
							</div>

							<div className="download-checklist__form-field">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									id="email"
									name="email"
									required
									autoFocus
									placeholder="Email Address"
									value={inputs.email}
									onChange={handleInputChange}
								/>
							</div>

							<div className="download-checklist__form-field">
								<button type="submit" className="btn btn--cta-register">
									{loading && <i className="fa fa-spinner fa-spin"></i>} download
								</button>
							</div>

							<div className="download-checklist__form-field">
								<span className="download-checklist__policy">
									By submitting this form, you agree to our{' '}
									<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
										Privacy Policy
									</a>{' '}
									and{' '}
									<a href={TERMS_AND_CONDITION_LINK} target="_blank" rel="noreferrer">
										Terms & Conditions
									</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</Popup>
		</>
	);
}
