import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={13}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.458 4H8.625V0h-4.25v4H1.542L6.5 9.333 11.458 4zM.833 10.667h11.334V12H.833v-1.333z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
