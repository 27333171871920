import * as React from "react"

function SvgCustomServiceRequests(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <title>{"s-question"}</title>
      <path
        d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM8 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1H7V9c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C5.9 3.6 6.9 3 8 3c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z"
        fill="#F7654B"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgCustomServiceRequests;
