import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M34 23h-4c-.6 0-1 .4-1 1s.4 1 1 1h4c.6 0 1-.4 1-1s-.4-1-1-1zM24 21c-1.3 0-2.4.9-2.8 2H20c-.6 0-1 .4-1 1s.4 1 1 1h1.2c.4 1.1 1.5 2 2.8 2 1.7 0 3-1.3 3-3s-1.3-3-3-3zM20 33h4c.6 0 1-.4 1-1s-.4-1-1-1h-4c-.6 0-1 .4-1 1s.4 1 1 1zM34 31h-1.2c-.4-1.2-1.5-2-2.8-2-1.7 0-3 1.3-3 3s1.3 3 3 3c1.3 0 2.4-.9 2.8-2H34c.6 0 1-.4 1-1s-.4-1-1-1z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
