import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 9h-2V3c0-.6-.4-1-1-1H9V0H7v2H4c-.6 0-1 .4-1 1v6H1c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1zM5 10V4h6v10H9v-3H7v3H5v-4z"
        fill="#F7654B"
      />
      <path d="M9 6H7v2h2V6z" fill="#F7654B" />
    </svg>
  )
}

export default SvgComponent
