import { notification } from 'antd';

export const openNotification = (type, title, message) => {
	notification[type]({
		message: title,
		description: message,
		duration: 3,
	});
};

export const handleCalculateROI = (teamSize, hourlyRate) => {
	if (teamSize <= 0 || hourlyRate <= 0) {
		return 0;
	} else {
		return numberWithCommas(Math.abs(-800 + teamSize * hourlyRate * 2000 * 0.15));
	}
};

export const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const validateEmail = (email) => {
	const regex =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const pluralize = (val, word, plural = word + 's') => {
	const _pluralize = (num, word, plural = word + 's') => ([1, -1].includes(Number(num)) ? word : plural);

	if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
	return _pluralize(val, word, plural);
};

export const checkIpLocation = async () => {
	try {
		const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
		const data = await res.text();
		const parsedData = data
			.trim()
			.split('\n')
			.reduce((obj, pair) => {
				pair = pair.split('=');
				return (obj[pair[0]] = pair[1]), obj;
			}, {});
		console.log('🚀 ~ file: index.js:48 ~ checkIpLocation ~ parsedData', parsedData);

		return parsedData;
	} catch (err) {
		console.log('🚀 ~ file: index.js:51 ~ checkIpLocation ~ err', err);
	}
};

export const splitArrToChunks = (array, parts) => {
	const copiedArray = [...array];
	let result = [];

	for (let i = parts; i > 0; i--) {
		result.push(copiedArray.splice(0, Math.ceil(copiedArray.length / i)));
	}

	return result;
};

export const omitKeyObject = (key, object) => {
	if (!key) return object;
	const { [key]: omitted, ...rest } = object;

	return rest;
};

export const getCookie = (cname) => {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
};

export const setCookie = (cname, cvalue, exdays, domain = '') => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = 'expires=' + d.toUTCString();
	let domainAttr = domain ? `;domain=${domain}` : '';
	document.cookie = `${cname}=${cvalue};${expires};path=/${domainAttr}`;
};

export const domainName = () => {
	if (window?.location?.hostname.includes('localhost')) return 'localhost';
	return '.' + window?.location?.hostname?.split('.')?.slice(-2)?.join('.');
};
