import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={55}
      height={55}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={55} height={55} rx={10} fill="#FFEAE6" />
      <path
        d="M27.818 23.636v2.182l2.91-2.909L27.817 20v2.182A5.817 5.817 0 0022 28c0 1.142.334 2.204.902 3.098l1.062-1.062a4.27 4.27 0 01-.51-2.036 4.367 4.367 0 014.364-4.364zm4.916 1.266l-1.061 1.062c.32.61.509 1.302.509 2.036a4.367 4.367 0 01-4.364 4.364v-2.182L24.91 33.09 27.82 36v-2.182A5.817 5.817 0 0033.635 28a5.768 5.768 0 00-.902-3.098z"
        fill="#FF6B4E"
      />
    </svg>
  )
}

export default SvgComponent
