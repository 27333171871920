import { customers, partners } from '../utils/images';

export default function Customers() {
	return (
		<>
			<section className="customers">
				<div className="container">
					<div className="customers__wrapper">
						<h2 className="customers__heading">Trusted by</h2>

						<div className="customers__logo">
							<img src={customers} alt="customers image" />
						</div>
					</div>
				</div>
			</section>

			<section className="customers">
				<div className="container">
					<div className="customers__wrapper">
						<h2 className="customers__heading">Proud to work with</h2>

						<div className="customers__logo">
							<img src={partners} alt="partners image" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
