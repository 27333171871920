import Script from 'next/script';
import { useWidthSize } from '../../hooks';

export default function ExternalScripts({ nonce }) {
	const windowWidth = useWidthSize();
	const isMobile = windowWidth <= 480;

	return (
		<>
			<Script src="https://www.googletagmanager.com/gtag/js?id=UA-167998759-1" nonce={nonce} />

			<Script src="https://www.googleoptimize.com/optimize.js?id=OPT-WWNCBG4" nonce={nonce} />

			<Script
				id="gtag-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', 'UA-167998759-1');
            gtag('config', 'AW-939226597');`,
				}}
			/>

			<Script
				id="gtag-conversion-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof url != 'undefined') {
                  // window.location = url;
                }
              };
              gtag('event', 'conversion', {
                send_to: 'AW-939226597/Lh90CI67x9sCEOXr7b8D',
                event_callback: callback,
              });
              return false;
            }`,
				}}
			/>

			<Script
				id="gtag-manager-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5BFWPXR')`,
				}}
			/>

			<Script
				id="hotjar-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2377237,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/>

			<Script
				id="fb-pixel-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '330769881591094');
            fbq('track', 'PageView');`,
				}}
			/>

			<Script
				id="nudgify-pixel-script"
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(w){
            var k="nudgify",n=w[k]||(w[k]={});
            n.uuid="e496f956-f0d4-404c-9193-cab2f60de21f";
            var d=document,s=d.createElement("script");
            s.src="https://pixel.nudgify.com/pixel.js";
            s.async=1;
            s.charset="utf-8";
            d.getElementsByTagName("head")[0].appendChild(s)
            })(window)`,
				}}
			/>

			<Script
				id="tawk-script"
				nonce={nonce}
				strategy="lazyOnload"
				dangerouslySetInnerHTML={{
					__html: `
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5f1a77d9a45e787d128c07c5/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();`,
				}}
			/>

			<Script
				id="calendly-script"
				nonce={nonce}
				strategy="lazyOnload"
				src="https://assets.calendly.com/assets/external/widget.js"
			/>

			<Script src="https://smtpjs.com/v3/smtp.js" nonce={nonce} />

			<link rel="stylesheet" href="https://github.hubspot.com/odometer/themes/odometer-theme-default.css" />

			<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />

			<noscript
				dangerouslySetInnerHTML={{
					__html: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5BFWPXR" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
				}}
			/>

			<noscript
				dangerouslySetInnerHTML={{
					__html: `
            <img height="1" width="1" src="https://www.facebook.com/tr?id=330769881591094&ev=PageView&noscript=1"/>`,
				}}
			/>
		</>
	);
}
