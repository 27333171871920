import { versusCompetitorHero } from '../../utils/images';

export default function VsCompetitorHeading() {
	return (
		<section className="container versus-competitor-heading">
			<div className="versus-competitor-heading__content">
				<h1 className="versus-competitor-heading__title">
					See why <span style={{ color: 'var(--primary' }}>facility</span>bot is the industry's leading CMMS
				</h1>

				<div className="versus-competitor-heading__desc">
					<p>
						With FacilityBot's Smart Facilities Management platform, building owners and managers have everything they
						need to manage and automate their operations at their fingertips.
					</p>

					<p>
						To help you compare FacilityBot's features against other CMMS systems, we have summarized some key
						differences below.
					</p>
				</div>
			</div>

			<img className="versus-competitor-heading__hero" src={versusCompetitorHero} alt="" />
		</section>
	);
}
