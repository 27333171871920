import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 13"}</title>
      <g transform="translate(-101 -378)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={101} y={378} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M125 412a1 1 0 01-.707-.293l-3-3a1 1 0 111.414-1.414l2.172 2.173 7.312-10.054a1 1 0 011.618 1.176l-8 11a1 1 0 01-.731.412H125zM134 402h3v2h-3zM131 406h6v2h-6zM128 410h9v2h-9z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent