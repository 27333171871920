import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M27 30c-.2 0-.3 0-.5-.1L19 25.7V34c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-8.3l-7.5 4.2c-.2.1-.3.1-.5.1z"
        fill="#F8644B"
      />
      <path
        d="M27 27.9l6-3.4V20c0-.6-.4-1-1-1H22c-.6 0-1 .4-1 1v4.5l6 3.4z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent