import {
	CheckCircleTwoTone,
	DollarCircleOutlined,
	MinusCircleTwoTone,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import { facilitybotLogo } from '../../utils/images';

const COMPARISON_FEATURES = [
	'Fault Reporting Through Messaging Apps',
	'Parts Management',
	'QR Code Reporting',
	'Unlimited Checklists',
	'Free Trial',
	'Room / Facilities Booking',
	'Hotdesks',
	'IoT Integrations',
	'Meter Charts',
	'Custom Dashboard',
	'Price',
];

const renderColumnCompetitor = (comparison) => {
	if (typeof comparison === 'boolean') {
		return comparison ? <CheckCircleTwoTone twoToneColor="#22D69B" /> : <MinusCircleTwoTone twoToneColor="#FF6377" />;
	} else if (typeof comparison === 'number') {
		return Array.from({ length: comparison }, (_, index) => <DollarCircleOutlined key={index} />);
	} else {
		return <QuestionCircleOutlined />;
	}
};

export default function VsCompetitorComparison({ name, slug, comparison }) {
	return (
		<section className="container versus-competitor-comparison">
			<h2 className="versus-competitor-comparison__title">
				<span style={{ color: 'var(--primary' }}>facility</span>bot VS {name}
			</h2>

			<div className="versus-competitor-comparison__table">
				<div className="table-row">
					<div className="table-column table-header table-header__heading">
						<span>Features</span>
					</div>
					<div className="table-column table-header table-header__icon">
						<img className="" src={facilitybotLogo} alt="" />
					</div>
					<div className="table-column table-header table-header__competitor-name">
						<span>{name}</span>
					</div>
				</div>

				{COMPARISON_FEATURES.map((name, index) => (
					<div className="table-row">
						<div className="table-column table-item">{name}</div>
						<div className="table-column table-item table-item__icon">
							{name === 'Price' ? <DollarCircleOutlined /> : <CheckCircleTwoTone twoToneColor="#22D69B" />}
						</div>
						<div className="table-column table-item table-item__icon">{renderColumnCompetitor(comparison[index])}</div>
					</div>
				))}
			</div>
		</section>
	);
}
