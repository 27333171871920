import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M34 28h-2v-6c0-.6-.4-1-1-1h-3v-2h-2v2h-3c-.6 0-1 .4-1 1v6h-2c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1zm-10 1v-6h6v10h-2v-3h-2v3h-2v-4z"
        fill="#F8644B"
      />
      <path d="M28 25h-2v2h2v-2z" fill="#F8644B" />
    </svg>
  )
}

export default SvgComponent
