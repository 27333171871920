import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <path
        d="M27 28a4 4 0 100-8 4 4 0 000 8zM26 33.171l3.054-3.054A18.53 18.53 0 0027 30c-3.192 0-5.539.795-6.837 1.382A1.989 1.989 0 0019 33.2V36h7v-2.829zM30 36h-2v-2l5-5 2 2-5 5z"
        fill="#F8644B"
      />
    </svg>
  )
}

export default SvgComponent
