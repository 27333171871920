import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 22"}</title>
      <g transform="translate(-1069 -612)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={1069} y={612} width={55} height={55} rx={10} />
        <g fill="#F7654B" fillRule="nonzero">
          <path d="M1104.555 634.168a1 1 0 00-1.387.277l-5.323 7.986-.837-.837-1.131 1.7 1.416 1.416a1 1 0 00.707.29c.033 0 .065 0 .1-.005a1 1 0 00.733-.44l6-9a1 1 0 00-.278-1.387zM1093 645a1 1 0 01-.707-.293l-3-3a1 1 0 111.414-1.414l2.138 2.138 5.323-7.986a1 1 0 011.664 1.11l-6 9a1 1 0 01-.732.445h-.1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
