import axios from 'axios';

const API_KEY_PRIVATE = process.env.NEXT_PUBLIC_SENDER_SECRET_API_KEY;

export default axios.create({
	baseURL: `/sender-api/`,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${API_KEY_PRIVATE}`,
	},
});
