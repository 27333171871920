import React from 'react';

function SvgComponent() {
	return (
		<svg
			width='55'
			height='55'
			fill='none'
			viewBox='0 0 55 55'
		>
			<rect width='55' height='55' fill='#FFEAE6' rx='8' />
			<g clipPath='url(#clip0)'>
				<path
					fill='#F8644B'
					d='M34.637 22.162l-7.5-2.142a.491.491 0 00-.274 0l-7.5 2.142a.5.5 0 00-.363.481c0 10.41 7.753 13.3 7.832 13.328a.5.5 0 00.336 0C27.247 35.943 35 33.053 35 22.643a.5.5 0 00-.363-.481zM29.274 30.5L27 29.3l-2.274 1.2.435-2.531-1.842-1.795 2.544-.369L27 23.5l1.137 2.3 2.544.369-1.842 1.8.435 2.531z'
				></path>
			</g>
			<defs>
				<clipPath id='clip0'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'
						transform='translate(19 20)'
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgComponent;
