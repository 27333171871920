import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 13"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M38 22h-1v6a1 1 0 01-1 1h-6.172L28 31h5l3 3v-3h2a1 1 0 001-1v-7a1 1 0 00-1-1z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M24 18h10a1 1 0 011 1v7a1 1 0 01-1 1h-5l-3 3v-3h-2a1 1 0 01-1-1v-7a1 1 0 011-1z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M35.285 35.305l-2.578-2.594a1 1 0 00-1.416-.002L29 35l-5-5 2.294-2.294a1 1 0 00.001-1.414l-2.58-2.584a1 1 0 00-1.415 0l-2.296 2.295L20 26c0 7.18 5.82 13 13 13l2.283-2.283a1 1 0 00.002-1.412zM36 31h-2c0-3.309-2.691-6-6-6v-2c4.411 0 8 3.589 8 8z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M32 31h-2c0-1.103-.897-2-2-2v-2c2.206 0 4 1.794 4 4z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <rect fill="#FFEAE6" width={55} height={55} rx={10} />
        <path
          d="M35 24h-1v6a1 1 0 01-1 1h-6.172L25 33h5l3 3v-3h2a1 1 0 001-1v-7a1 1 0 00-1-1z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M21 20h10a1 1 0 011 1v7a1 1 0 01-1 1h-5l-3 3v-3h-2a1 1 0 01-1-1v-7a1 1 0 011-1z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent;
