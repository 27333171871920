import * as React from "react";

function SvgAutomatedFaqResponse(props) {
  return (
    <svg width={16} height={14} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M13 14H1c-.8 0-1.3-.9-.8-1.6L3 8.7V3c0-1.7 1.3-3 3-3h7c1.7 0 3 1.3 3 3v8c0 1.7-1.3 3-3 3zM3 12h10c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1H6c-.6 0-1 .4-1 1v6c0 .2-.1.4-.2.6L3 12z" />
        <path d="M7 4h5v2H7zM7 8h5v2H7z" />
      </g>
    </svg>
  );
}

export default SvgAutomatedFaqResponse;
