import Footer from './Footer';
import Header from './Header';
import Heading from './Heading';
import Layout from './Layout';

import AccordionMenu from './AccordionMenu';
import Popup from './Popup';

import MailForm from './Form/mail';
import CtaForm from './Form/cta';
import SignupForm from './Form/signup';
import DownloadBrochureForm from './Form/download_brochure';
import DownloadChecklistPdfForm from './Form/download_checklist_pdf';
import ExitIntentForm from './Form/exit_intent';

import ChannelsList from './ChannelsList';
import FontAwesomeIcon from './FontAwesomeIcon';

import StatisticsWrapper from './StatisticsWrapper';

// Homepage page components
import Faq from './Faq';
import PricingPlansTab from './PricingPlansTab';
import Customers from './Customers';
import Features from './Features';
import Demo from './Demo';
import LinkedinWidget from './Widget/LinkedinWidget';

// VersusCompetitor components
import VsCompetitorHeading from './VsCompetitor/Heading';
import VsCompetitorComparison from './VsCompetitor/Comparison';
import VsCompetitorAdvantages from './VsCompetitor/Advantages';
import VsCompetitorFaq from './VsCompetitor/Faq';

export {
	Footer,
	Header,
	Heading,
	Layout,
	MailForm,
	CtaForm,
	SignupForm,
	Popup,
	AccordionMenu,
	ChannelsList,
	FontAwesomeIcon,
	StatisticsWrapper,
	DownloadBrochureForm,
	DownloadChecklistPdfForm,
	ExitIntentForm,
	PricingPlansTab,
	Faq,
	Customers,
	Features,
	Demo,
	VsCompetitorHeading,
	VsCompetitorComparison,
	VsCompetitorAdvantages,
	VsCompetitorFaq,
	LinkedinWidget,
};
