import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 11"}</title>
      <g transform="translate(-640)" fill="none" fillRule="evenodd">
        <path
          d="M675.285 35.305l-2.578-2.594a1 1 0 00-1.416-.002L669 35l-5-5 2.294-2.294a1 1 0 00.001-1.414l-2.58-2.584a1 1 0 00-1.415 0l-2.296 2.295L660 26c0 7.18 5.82 13 13 13l2.283-2.283a1 1 0 00.002-1.412z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <rect fill="#FFEAE6" x={640} width={55} height={55} rx={10} />
        <path
          fill="#F7654B"
          fillRule="nonzero"
          d="M660 32h4v4h-4zM663 29h4v2h2v-2h4v2h2v-3c0-.6-.4-1-1-1h-5v-2h-2v2h-5c-.6 0-1 .4-1 1v3h2v-2z"
        />
        <path
          d="M676 31h-2c0-3.309-2.691-6-6-6v-2c4.411 0 8 3.589 8 8z"
          fill="#F7654B"
          fillRule="nonzero"
        />
        <path
          d="M672 31h-2c0-1.103-.897-2-2-2v-2c2.206 0 4 1.794 4 4zM666 32h4v4h-4zM666 20h4v4h-4zM672 32h4v4h-4z"
          fill="#F7654B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
