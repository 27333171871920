import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import facilityBotApi from '../../services/facilityBot';
import { Skeleton } from 'antd';
import { greatUserExperienceAward, risingStarAward, SIFMAAward, asiaPacificAward } from '../../utils/images';

const Odometer = dynamic(
	async () => {
		return await import('react-odometerjs');
	},
	{
		ssr: false,
		loading: () => 0,
	},
);

const StatsNumber = ({ value }) => {
	return <Odometer value={value} theme="default" animation="count" duration={1000} format="(,ddd)" />;
};

function isClientSide(docObject) {
	if (_.isUndefined(docObject)) return false;
	return true;
}

export default function StatisticsWrapper() {
	const [requestsInit, setRequests] = useState(null);
	const [checklistsInit, setChecklists] = useState(null);
	const [totalUsers, setTotalUsers] = useState(null);
	const [isClient, setIsClient] = useState(false);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchStatistics() {
			try {
				const res = await facilityBotApi.get('/clients/custom_statistics');
				const { requests, checklists, total_users } = res.data;

				setRequests(requests);
				setChecklists(checklists);
				setTotalUsers(total_users ? total_users : 25940);
			} catch (err) {
				console.error('🚀 ~ file: index.js ~ line 53 ~ fetchStatistics ~ err', err);

				setRequests(18000);
				setChecklists(54000);
				setTotalUsers(25940);
			}
		}
		fetchStatistics();

		const interval = setInterval(() => {
			setLoading(false);
		}, 3000);

		// Ensure this code only runs on the client-side
		if (isClientSide(document)) {
			setIsClient(true);
		}

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="hero__stats">
			<div className="hero__row">
				<div className="hero__block hero__block--lg">
					<span className="stat-text">Deployed in over</span>
					<span className="stat-number stat-number--after">10,000,000</span>
				</div>
				<div className="hero__block">
					<span className="stat-text">Faults resolved</span>
					<div className="stat-number ">
						<Skeleton loading={loading} active paragraph={false}>
							{isClient && <StatsNumber value={requestsInit} />}
						</Skeleton>
					</div>
				</div>
			</div>
			<div className="hero__row">
				<div className="hero__block hero__block--lg">
					<span className="stat-text">Preventive Maintenance Checklists created</span>
					<div className="stat-number">
						<Skeleton loading={loading} active paragraph={false}>
							{isClient && <StatsNumber value={checklistsInit} />}
						</Skeleton>
					</div>
				</div>
				<div className="hero__block">
					<span className="stat-text">Total Users</span>
					<div className="stat-number">
						<Skeleton loading={loading} active paragraph={false}>
							{isClient && <StatsNumber value={totalUsers} />}
						</Skeleton>
					</div>
				</div>
			</div>

			<div className="hero__row hero__row--unset">
				<div className="hero__img-wrapper">
					<img src={asiaPacificAward} alt="" className="" />
					<img src={SIFMAAward} alt="" className="" />
					<img src={greatUserExperienceAward} alt="" className="" />
					<img src={risingStarAward} alt="" className="" />
				</div>
				<div className="hero__block hero__block--md"></div>
			</div>
		</div>
	);
}
