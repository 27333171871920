import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 53"}</title>
      <g transform="translate(-427 -2483)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={2483} width={55} height={55} rx={10} />
        <g transform="translate(447 2503)" fill="#F7654B" fillRule="nonzero">
          <circle cx={7.5} cy={8.5} r={1.5} />
          <path d="M12.5 0C10.6 0 9 1.6 9 3.5S10.6 7 12.5 7 16 5.4 16 3.5 14.4 0 12.5 0z" />
          <path d="M13 9c-.2 2.8-2.6 5-5.5 5-3 0-5.5-2.5-5.5-5.5C2 5.6 4.2 3.3 7 3c.1-.7.3-1.4.6-2h-.1C3.4 1 0 4.4 0 8.5S3.4 16 7.5 16 15 12.6 15 8.5v-.1c-.6.3-1.3.5-2 .6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
