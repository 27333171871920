import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
      <rect width={55} height={55} rx={8} fill="#FFEAE6" />
      <g clipPath="url(#prefix__clip0)" fill="#F8644B">
        <path d="M21.75 25a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM32.25 25a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM32.5 26a4.76 4.76 0 00-3.474 2.032c-.266.315-.56.606-.879.868a1.666 1.666 0 01.331 1.558A5.227 5.227 0 0030 29.191V34a1 1 0 001 1h3a1 1 0 001-1v-5.5a2.5 2.5 0 00-2.5-2.5zM26.8 29.25c-.693 0-1.144-.463-1.823-1.218A4.76 4.76 0 0021.5 26a2.5 2.5 0 00-2.5 2.5V34a1 1 0 001 1h3a1 1 0 001-1v-4.809a3.718 3.718 0 002.8 1.559.75.75 0 100-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(19 20)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
