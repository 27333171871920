import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={17}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 16.025h4c.6 0 1-.4 1-1v-9c0-.3-.1-.6-.4-.8l-6-5c-.4-.3-.9-.3-1.3 0l-6 5c-.2.2-.3.5-.3.8v9c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-3h2v3c0 .6.4 1 1 1zm-4-6c-.6 0-1 .4-1 1v3H2v-7.5l5-4.2 5 4.2v7.5h-2v-3c0-.6-.4-1-1-1H5z"
        fill="#F7654B"
      />
    </svg>
  )
}

export default SvgComponent
