export default function Heading({ title, desc, ...props }) {
	return (
		<section className="heading-container">
			<div className="container">
				<div className="heading">
					<h1 className="heading__title">{title}</h1>
					<h2 className="heading__desc">{desc}</h2>
				</div>
			</div>
		</section>
	);
}
