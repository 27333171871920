import Link from 'next/link';

import { useToggleMenu } from '../../hooks';
import { FEATURE_GROUPS, FEATURES_SUBMENU } from '../../data/features';
import { splitArrToChunks } from '../../utils';

export const calculateColumns = (number) => {
	const maxItems = 10;
	return Math.ceil(number / maxItems);
};

export const SubmenuDropdownItem = ({ link, icon, name }) => (
	<li className="dropdown__item">
		<Link href={link} className="dropdown__link">
			{icon}
			<span>{name}</span>
		</Link>
	</li>
);

const SubmenuDropdownColumn = ({ data, columns }) => (
	<div className="dropdown__column-wrapper">
		{splitArrToChunks(data, columns).map((chunk, columnIndex) => {
			return (
				<ul key={columnIndex} className="dropdown__column">
					{chunk.map((item, itemIndex) => (
						<SubmenuDropdownItem key={itemIndex} {...item} />
					))}
				</ul>
			);
		})}
	</div>
);

export default function NavFeatItem({ className, displayText }) {
	const [toggle, setToggle] = useToggleMenu();

	return (
		<li className="header__nav-item header__nav-item--dropdown" onClick={setToggle}>
			<span>{displayText}</span>

			<ul className={`submenu ${className} ${toggle ? 'is-mobile' : ''}`}>
				<div className="dropdown__section-list">
					{FEATURE_GROUPS.map((group, groupIndex) => {
						const submenu = FEATURES_SUBMENU.filter((item) => item?.group === groupIndex + 1);

						return (
							<div className="dropdown__section" key={groupIndex}>
								<h3
									className="dropdown__column-label"
									style={{
										color: group.color,
									}}
								>
									{group.name}
								</h3>

								<SubmenuDropdownColumn data={submenu} columns={calculateColumns(submenu.length)} />
							</div>
						);
					})}
				</div>
			</ul>
		</li>
	);
}
