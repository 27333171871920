import React from 'react';
import { Collapse } from 'antd';
import { AddIcon, MinusIcon } from './icon';
import Icon from '@ant-design/icons';

export default function AccordionMenu(props) {
	return (
		<Collapse
			{...props}
			className="fc-theme"
			accordion
			expandIcon={({ isActive }) => (isActive ? <Icon component={AddIcon} /> : <Icon component={MinusIcon} />)}
		>
			{props.children}
		</Collapse>
	);
}
