import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 19"}</title>
      <g transform="translate(-741 -612)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={612} width={55} height={55} rx={10} />
        <g transform="translate(761 632)" fill="#F7654B" fillRule="nonzero">
          <path d="M13 5.6A7.5 7.5 0 015.6 13 5.5 5.5 0 1013 5.6z" />
          <circle cx={5.5} cy={5.5} r={5.5} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
