import * as React from "react";

function SvgFacilityAndRoomBooking(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g fill="#F7654B" fillRule="nonzero">
        <path d="M11 16a1 1 0 01-.707-.293l-2-2 1.414-1.414L11 13.586l3.293-3.293 1.414 1.414-4 4A1 1 0 0111 16z" />
        <path d="M7 14H2V5h12v4h2V3a1 1 0 00-1-1h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h6v-2z" />
      </g>
    </svg>
  );
}

export default SvgFacilityAndRoomBooking;
