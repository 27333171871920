import { useEffect, useContext } from 'react';
import { NonceContext } from '../../pages/_app';

function LinkedInFollowCompany({ companyId, counter = 'bottom', lang = 'en_US' }) {
	const nonce = useContext(NonceContext);

	useEffect(() => {
		((doc, tag, id) => {
			const [element] = doc.querySelectorAll(tag);
			if (doc.getElementById(id)) {
				return;
			}
			let js = doc.createElement('script');
			js.id = id;
			js.type = 'text/javascript';
			js.src = '//platform.linkedin.com/in.js';
			js.innerHTML = `lang: ${lang}`;
			element.insertBefore(js, element.lastChild);
		})(document, '.linkedin-follow-company', 'linkedin-jssdk');

		return () => (window.IN = {});
	}, [lang]);

	return (
		<div className="linkedin-follow-company">
			<script type="IN/FollowCompany" data-id={companyId} data-counter={counter} nonce={nonce} />
		</div>
	);
}

export default LinkedInFollowCompany;
