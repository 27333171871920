import { useEffect } from 'react';

import MailForm from '../Form/mail';
import HeaderLogo from './HeaderLogo';
import NavLinkItem from './NavLinkItem';
import NavItem from './NavItem';
import NavFeatItem from './NavFeatItem';

import { useToggleMenu, useWidthSize } from '../../hooks';

import { SIGN_IN_LINK } from '../../constants';
import { RESOURCES_SUBMENU } from '../../data/resources';
import NavSolutionItem from './NavSolutionItem';

export default function Header() {
	const [mobileMenu, setMobileMenu] = useToggleMenu();
	const windowWidth = useWidthSize();

	function handleNavMobile() {
		let navList = document.querySelector('.header__nav');
		let wrapper = document.querySelector('.wrapper');

		if (navList.classList.contains('is-mobile')) {
			navList.classList.remove('is-mobile');
			wrapper.classList.remove('no-scroll');
		} else {
			navList.classList.add('is-mobile');
			wrapper.classList.add('no-scroll');
		}

		setMobileMenu();
	}

	function handleScroll() {
		let header = document.getElementById('headerFixed');
		if (!header) return;

		let offsetTop = header.offsetTop;
		if (window.scrollY > offsetTop) {
			header.classList.add('is-fixed');
		} else {
			header.classList.remove('is-fixed');
		}
	}

	useEffect(() => {
		let navList = document.querySelector('.header__nav');
		let wrapper = document.querySelector('.wrapper');

		if (windowWidth > 1024) {
			if (navList.classList.contains('is-mobile')) {
				navList.classList.remove('is-mobile');
				wrapper.classList.remove('no-scroll');
			}

			if (mobileMenu) {
				setMobileMenu(false);
			}
		}
	}, [windowWidth, mobileMenu, setMobileMenu]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header className="header" id="headerFixed">
			<div className="container">
				<div className="header__wrapper">
					<HeaderLogo />

					<div
						className={`header__nav-mobile ${mobileMenu ? 'header__nav-mobile--close' : ''}`}
						onClick={handleNavMobile}
					>
						<span>{mobileMenu ? 'Close Menu' : 'Menu'}</span>
					</div>

					<nav className="header__nav">
						<ul className="header__nav-list">
							<NavLinkItem href="/#pricing-plans" displayText="Pricing" onClick={handleNavMobile} />

							<NavFeatItem className="submenu--features" displayText="Features" />

							<NavItem
								className="submenu--resources"
								displayText="Resources"
								submenu={{
									data: RESOURCES_SUBMENU,
									columns: 3,
								}}
							/>

							<NavSolutionItem className="submenu--building-types" displayText="Solutions" />

							<NavLinkItem href={SIGN_IN_LINK} displayText="Sign In" />
						</ul>
					</nav>

					<MailForm />
				</div>
			</div>
		</header>
	);
}
