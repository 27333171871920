import { useState } from 'react';
import { Modal } from 'antd';

export default function ExpandPricingTable({ children }) {
	const [visible, setVisible] = useState(false);

	return (
		<div className="expand-compare-table">
			<span onClick={() => setVisible(!visible)}>See more</span>

			<Modal
				centered
				open={visible}
				onCancel={() => setVisible(!visible)}
				footer={null}
				width="80vw"
				closable={false}
				wrapClassName="modal-pricing"
				// className="modal-pricing"
			>
				{children}
			</Modal>
		</div>
	);
}
