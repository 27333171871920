import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 47"}</title>
      <g transform="translate(-741 -2236)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={741} y={2236} width={55} height={55} rx={10} />
        <g transform="translate(761 2256)" fill="#F7654B" fillRule="nonzero">
          <path d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 14H2V2h12v12z" />
          <circle cx={8} cy={12} r={1} />
          <path d="M7.1 5.5c.2-.3.5-.5.9-.5.6 0 1 .4 1 1 0 .3-.1.4-.6.7C7.8 7.1 7 7.7 7 9v1h2V9c0-.2 0-.3.5-.6.6-.4 1.5-1 1.5-2.4 0-1.7-1.3-3-3-3-1.1 0-2.1.6-2.6 1.5l-.5.9 1.7 1 .5-.9z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
