import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from '../../constants';
import brochureThumbnail from '../../public/images/brochure_thumbnail.jpg';
import facilityBotBrochure from '../../public/facility_bot_brochure.pdf';
import LinkedInFollowCompany from '../../components/FollowComponent';
import { getCookie, setCookie } from '../../utils';
import mailjetService, { interestedLeadsListId } from '../../services/mailjetService';
import senderService from '../../services/senderService';
import { SENDER_GROUPS } from '../../constants/sender';

export default function ExitIntentForm() {
	let initialInputs = {
		name: '',
		email: '',
	};

	const [inputs, setInputs] = useState(initialInputs);
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isShowed, setIsShowed] = useState(false);

	const checkCookie = () => {
		const isPopupShowed = getCookie('isPopupShowed');
		if (isPopupShowed !== '') {
			setIsShowed(true);
		} else {
			setCookie('isPopupShowed', 'true', 15);
			setIsShowed(false);
		}
	};

	useEffect(() => {
		checkCookie();

		const mouseoutEvent = (e) => {
			if (e.clientY < 0 && !isShowed) {
				if (document.getElementsByClassName('calendly-overlay').length) return;
				if (['brochure-download-confirmation', 'booking-confirmation'].includes(window.location.pathname)) return;

				setVisible(!visible);
				setIsShowed(true);
			}
		};

		window.addEventListener('mouseout', mouseoutEvent);

		return () => window.removeEventListener('mouseout', mouseoutEvent);
	}, [isShowed, visible]);

	const handleInputChange = (e) => {
		e.persist();

		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const clearState = () => {
		setLoading(false);
		setInputs({ ...initialInputs });
	};

	const handleSubmit = async (e) => {
		setLoading(true);

		try {
			e.preventDefault();
			// will drop the user information into Interested Leads contact list in mailjet
			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Name: inputs.name,
				Properties: {
					name: inputs.name,
				},
				Action: 'addnoforce',
				Email: inputs.email,
			});

			await senderService.post('/subscribers', {
				email: inputs.email,
				firstname: inputs.name,
				groups: [SENDER_GROUPS.BROCHURE_DOWNLOADS],
			});
		} catch (err) {
			console.error('🚀 ~ file: exit_intent.js ~ line 65 ~ handleSubmit ~ err', err);
		} finally {
			let downloadBtn = document.createElement('a');
			downloadBtn.href = facilityBotBrochure;
			downloadBtn.download = 'facility_bot_brochure';
			downloadBtn.click();

			window.location.href = '/brochure-download-confirmation';
		}
	};

	return (
		<Modal
			centered
			open={visible}
			onCancel={() => setVisible(false)}
			afterClose={() => clearState()}
			footer={null}
			wrapClassName="popup-wrapper"
			width="1200px"
		>
			<div className="exit-intent">
				<div className="exit-intent__left">
					<img src={brochureThumbnail} alt="Brochure thumbnail" />
				</div>

				<div className="exit-intent__right">
					<div className="exit-intent__title-wrapper">
						<span className="exit-intent__title">Thanks for visiting our website</span>

						<span className="exit-intent__subtitle">Let's keep in touch !</span>

						<span className="exit-intent__subtitle">Follow our Linked In Page to keep track of our new features</span>

						<LinkedInFollowCompany companyId={31130457} />

						<span className="exit-intent__subtitle exit-intent__subtitle--mt-2">
							Or download our free brochure to find out more !
						</span>
					</div>

					<form className="exit-intent__form" onSubmit={handleSubmit}>
						<div className="exit-intent__form-field">
							<label htmlFor="name">Name</label>
							<input
								type="text"
								id="name"
								name="name"
								required
								placeholder="Name"
								value={inputs.name}
								onChange={handleInputChange}
							/>
						</div>

						<div className="exit-intent__form-field">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								id="email"
								name="email"
								required
								autoFocus
								placeholder="Email Address"
								value={inputs.email}
								onChange={handleInputChange}
							/>
						</div>

						<div className="exit-intent__form-field">
							<button type="submit" className="btn btn--cta-register">
								{loading && <i className="fa fa-spinner fa-spin"></i>} download brochure
							</button>
						</div>

						<div className="exit-intent__form-field">
							<span className="exit-intent__policy">
								By submitting this form, you agree to our{' '}
								<a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
									Privacy Policy
								</a>{' '}
								and{' '}
								<a href={TERMS_AND_CONDITION_LINK} target="_blank" rel="noreferrer">
									Terms & Conditions
								</a>
							</span>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
}
