import { useState, useEffect } from 'react';

export default function useWidthSize() {
	const [size, setSize] = useState(0);

  function updateSize() {
    setSize(window.innerWidth);
  }

	useEffect(() => {
		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return size;
}
