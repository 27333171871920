import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={55} height={55} viewBox="0 0 55 55" {...props}>
      <title>{"Rectangle Copy 57"}</title>
      <g transform="translate(-427 -2734)" fill="none" fillRule="evenodd">
        <rect fill="#FFEAE6" x={427} y={2734} width={55} height={55} rx={10} />
        <g transform="translate(447 2754)" fill="#F7654B" fillRule="nonzero">
          <circle cx={2} cy={2} r={2} />
          <circle cx={2} cy={10} r={2} />
          <path d="M6 1h10v2H6zM6 13h10v2H6zM6 9h10v2H6zM6 5h10v2H6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
